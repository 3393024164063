<ion-content>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-button (click)="annuleCreationProjet($event)">Annuler</ion-button>
        </ion-buttons>
        <ion-title>Nouveau projet</ion-title>
        <ion-buttons slot="end">
          <ion-button (click)="confirmeCreationProjet(libelle.value, type.value, nbConsommateurs.value)" [strong]="true">Confirmer</ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content class="ion-padding">
      <ion-grid>
        <ion-row>
          <ion-col>
            <ion-input #libelle type="text" label="Nom du Projet" name="libelle" placeholder="Le nom du projet ..." 
              fill="outline" labelPlacement="stacked" error-text="Vous devez saisir le nom du projet !"
              value="" ngModel required>
            </ion-input>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <ion-select #type label="Type de projet" name="type" 
              fill="outline" labelPlacement="stacked" error-text="Vous devez choisir le type d'installation !"
              value="" >
              <ion-select-option *ngFor="let o of listeTypesProjet; index as i" value="{{o}}">{{ getLibelleType(i) }}</ion-select-option>
            </ion-select>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <ion-input #nbConsommateurs type="number" label="Nombre de consommateurs" name="nb_consommateurs" placeholder="Nombre de consommateurs ..." 
              inputmode="numeric" fill="outline" labelPlacement="stacked" error-text="Vous devez saisir le nombre de consommateurs !"
              [min]="1" [max]="100"
              value="" ngModel required>
            </ion-input>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-content>
</ion-content>