/* eslint-disable @angular-eslint/no-empty-lifecycle-method */
import { Component, ElementRef, Input, OnInit, ViewChild, AfterViewChecked, effect } from '@angular/core';
import { ECharts, EChartsOption, number} from 'echarts';
import { SankeyNodeItemOption } from './echart-modified';
import { NgxEchartsDirective, provideEcharts } from 'ngx-echarts';
import { ProjetPage } from 'src/app/Pages/projet/projet.page';
import { ProjetService } from 'src/app/services/projet.service';
import { timer } from 'rxjs';

@Component({
  selector: 'app-bilan-global-flux-graph-component',
  templateUrl: './bilan-global-flux-graph-component.component.html',
  styleUrls: ['./bilan-global-flux-graph-component.component.scss'],
  standalone: true,
  imports: [NgxEchartsDirective],
  providers: [ provideEcharts() ]
})
export class BilanGlobalFluxGraphComponentComponent  implements OnInit, AfterViewChecked {
  @Input() id_scneario!: number;

  echartFluxOption: EChartsOption = {
    tooltip: {
      trigger: 'item', 
      formatter: (params: any) => {
        if (params.dataType === 'edge') { 
          const source = params.data.source;
          const target = params.data.target;
          const value = this.ps.formatteNombre(params.data.value, 0);
          return `${source} → ${target}: ${value} kWh`;
        }
        else if (params.dataType === 'node') {
          const value = this.ps.formatteNombre(params.value, 0);
          return `${params.name}: ${value} kWh`;
        }
        return ''; 
      }
    },
    series: {
      type: 'sankey',
      layoutIterations: 0,
      emphasis: {
        focus: 'adjacency'
      },
      data: [ ],
      links: [ ]
    }
  };
  

  colorNodeSoutirage = '#ff0000';
  colorNodeSurplus = '#92d050';
  colorNodeProducteur = '#3333ff';
  colorNodeConsomateur = '#fd9800'; //jaune foncé

  colorFluxFromSoutirage = '#ff0000';
  colorFluxFromProdToConso = '#991A80'; // violet
  colorFluxFromProdToSurplus = '#92d050';
  colorFluxACI = '#3333ff'; // bleu foncé

  constructor(private _parent: ProjetPage, private ps: ProjetService) {
    effect( () => {   // Recharger le projetCourant sur la sollicitation du signal 
      const etat = this.ps.bilanEnergieModifie();
      if (etat) {
        //console.log('Le signal bilanEnergieModifie passe à TRUE !');
        this.grapheInitatlization();
        timer(500).subscribe(() => { this.rearmeSignalBilanEnergie();});
      }
    });
   }

  ngOnInit() {
    this.grapheInitatlization();
  }

  ngAfterViewChecked(): void {
    this.legendGeneration();    
  }

  onChartInit(ec: any) { // Méthode appelée à l'initialisation du graphique
    //console.log('onChartInit()', ec.id);
  }

  onChartClick(ev: any) { // Méthode appelée lors d'un clic sur le graphique
    //console.log('onChartClick', ev);
  }

  private rearmeSignalBilanEnergie() {
    if (this.ps.bilanEnergieModifie())
      this.ps.bilanEnergieModifie.set(false);
  }

  private legendGeneration() {
    let legend = document.getElementById('legend');
    let legendLi = legend?.querySelectorAll('li');
    legendLi?.forEach(li => {
      let legendColor:HTMLElement | null = li?.querySelector('.legend-color');
      if(legendColor != null){
        switch(li.id){
          case 'node-producteur':
            legendColor.style.backgroundColor = this.colorNodeProducteur;
            legendColor.style.borderRadius = '0%';
            break;
          case 'node-consommateur':
            legendColor.style.backgroundColor = this.colorNodeConsomateur;
            legendColor.style.borderRadius = '0%';
            break;
          case 'flux-from-soutirage':
            legendColor.style.backgroundColor = this.colorFluxFromSoutirage;
            break;
          case 'flux-from-prod':
            legendColor.style.backgroundColor = this.colorFluxFromProdToConso;
            break;
          case 'flux-to-surplus':
            legendColor.style.backgroundColor = this.colorFluxFromProdToSurplus;
            break;
          case 'flux-aci':
            legendColor.style.backgroundColor = this.colorFluxACI;
            break;
        }
      }
    });
  }

  private calculateDynamicMargin(maxNameLength: number) {
    let leftMargin = '10%';
    let rightMargin = '10%';
  
    if (maxNameLength > 10 && maxNameLength <= 20) {
      leftMargin = '13%';
      rightMargin = '14%';
    } else if (maxNameLength > 20 && maxNameLength <= 30) {
      leftMargin = '18%';
      rightMargin = '18%';
    } else if (maxNameLength > 30) {
      leftMargin = '28%';
      rightMargin = '29%';
    }
  
    return { left: leftMargin, right: rightMargin };
  }

  private grapheInitatlization() {
    let data: SankeyNodeItemOption[] = [{
        name: 'Soutirage global',
        itemStyle: {
          color: this.colorNodeSoutirage
        },
        label:{ position: 'left' }
      },
      { 
        name: 'Surplus global', 
        itemStyle:{ 
          color: this.colorNodeSurplus 
        }
      }
    ];
    let links: any[] = [];
    const projet = this._parent.projetCourant;
    const participants = projet?.participants;
    const scenarioCourant = projet?.scenarios[this.id_scneario];
    let id_nom_participant = new Map<number, string>();
    participants?.forEach(participant => {
      id_nom_participant.set(participant.id, participant.nom);
    });
    scenarioCourant?.bilanEnergetique?.indicateurs.participants.forEach(participant => {
      const nom = id_nom_participant.get(participant.id);
      if (nom) {
        data.push({ name: nom, itemStyle: { color: this.colorNodeProducteur }});
      }
    });
    let liste_conso = new Array<string>();
    scenarioCourant?.bilanEnergetique?.indicateurs.participants.forEach(participant => {
      let nom = id_nom_participant.get(participant.id);
      let nom_prod = null;
      let nom_conso = null;
      if(participant.total_prod > 0 && participant.total_conso > 0 && nom != null){
        nom_prod = nom + '(prod)';
        nom_conso = nom + '(conso)';
        liste_conso.push(nom);
      }
      if(participant.total_conso - participant.energie_alloue > 0){
        if(nom_conso != null){
          links.push({ source: 'Soutirage global', target: nom_conso, value: participant.total_conso - participant.energie_alloue, lineStyle: { color: this.colorFluxFromSoutirage } });
        }
        else{
          links.push({ source: 'Soutirage global', target: nom, value: participant.total_conso - participant.energie_alloue, lineStyle: { color: this.colorFluxFromSoutirage } });
          this.changeTargetColor(data, nom);
        }
      }
      if(participant.total_prod > 0){
        //console.log('graphe Participant ...', participant);
        participant.repartition_prod.forEach(prod => {
          if (participant.id != prod.id_consommateur){
            let targetName = null;
            if(prod.energie > 0){
              let prodName = id_nom_participant.get(prod.id_consommateur);
              if(typeof prodName == 'string' && liste_conso.indexOf(prodName) > -1){
                targetName = prodName + '(conso)';
              }else{
                targetName = prodName;
              }
              if(nom_prod != null){
                links.push({ source: nom_prod, target: targetName, value: prod.energie, lineStyle: { color: this.colorFluxFromProdToConso } });
                if(participant.energie_alloue_aci > 0 && !(links.some(link => link.source === nom_prod && link.target === nom + '(conso)' && link.value === participant.energie_alloue_aci && link.lineStyle.color === this.colorFluxACI))){
                  links.push({ source: nom_prod, target: nom + '(conso)', value: participant.energie_alloue_aci, lineStyle: { color: this.colorFluxACI } });
                }
              }else{
                links.push({ source: nom, target: targetName, value: prod.energie, lineStyle: { color: this.colorFluxFromProdToConso } });
                this.changeTargetColor(data, targetName);
                if(participant.energie_alloue_aci > 0 && !(links.some(link => link.source === nom && link.target === nom + '(conso)' && link.value === participant.energie_alloue_aci && link.lineStyle.color === this.colorFluxACI))){
                  links.push({ source: nom, target: nom + '(conso)', value: participant.energie_alloue_aci, lineStyle: { color: this.colorFluxACI } });
              }
            }
          }
          }
        });
      }
      if(participant.surplus_acc > 0){
        if(nom_prod != null){
          links.push({ source: nom_prod, target: 'Surplus global', value: participant.surplus_acc, lineStyle: { color: this.colorFluxFromProdToSurplus } });
        }else{
          links.push({ source: nom, target: 'Surplus global', value: participant.surplus_acc, lineStyle: { color: this.colorFluxFromProdToSurplus } });
        }
      }
    });

    const existingNodes = new Set(data.map(node => node.name));

    const linkNodes = new Set<string>();
    links.forEach(link => {
      linkNodes.add(link.source as string);
      linkNodes.add(link.target as string);
    });

    const removeNodeWithoutSuffix = (nodeName: string) => {
      const baseName = nodeName.replace(/\(conso\)|\(prod\)/g, ''); 
      const indexToRemove = data.findIndex(node => node.name === baseName);
      if (indexToRemove !== -1) {
        data.splice(indexToRemove, 1); 
        existingNodes.delete(baseName); 
      }
    };

    linkNodes.forEach(nodeName => {
      if (!existingNodes.has(nodeName)) {
        removeNodeWithoutSuffix(nodeName); 
        if (nodeName.includes('conso')) {
          data.push({
            name: nodeName,
            itemStyle: {
              color: this.colorNodeConsomateur
            }
          });
        } else {
          data.push({
            name: nodeName,
            itemStyle: {
              color: this.colorNodeProducteur
            }
          });
        }        
        existingNodes.add(nodeName); 
      }
    });

    this.deplaceSurplus(data);
    data.forEach(node => {
      if(node.itemStyle?.color == this.colorNodeProducteur){
        node.label = { position: 'left' };
      }
    });
    const maxNodeNameLength = data.reduce((maxLength, node) => {
      return Math.max(maxLength, node.name?.toString().length || 0);
    }, 0);
    const dynamicMargin = this.calculateDynamicMargin(maxNodeNameLength);
    this.echartFluxOption.series = {
      type: 'sankey',
      layoutIterations: 0,
      emphasis: {
        focus: 'adjacency'
      },
      data: data,
      links: links,
      left: dynamicMargin.left,
      right: dynamicMargin.right,  
      nodeWidth: 20,  
      nodeGap: 10
    };
  }

  deplaceSurplus(data: Array<any>) {
    const surplusIndex = data.findIndex(item => item.name === 'Surplus global');
    if (surplusIndex !== -1) {
      const surplusObject = data.splice(surplusIndex, 1)[0];
      data.push(surplusObject);
    }
  }

  changeTargetColor(data: Array<any>, target: string | undefined) {
    const targetIndex = data.findIndex(item => item.name === target);
    if (targetIndex !== -1) {
      data[targetIndex].itemStyle = { color: this.colorNodeConsomateur };
    }
  }
}
