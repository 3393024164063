/* eslint-disable @angular-eslint/no-empty-lifecycle-method */
import { Component, Input, OnInit, Output, EventEmitter, input } from '@angular/core';
import { IonInput, IonButton } from "@ionic/angular/standalone";
import { FormsModule } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { AuthentificationService } from 'src/app/services/authentification.service';
import { SSE, SSEHeaders, SSEOptions } from 'sse.js';
import { ProjetPage } from 'src/app/Pages/projet/projet.page';

@Component({
  selector: 'app-dialog-new-scenario',
  templateUrl: './dialog-new-scenario.component.html',
  styleUrls: ['./dialog-new-scenario.component.scss'],
  standalone: true,
  imports: [IonButton, IonInput, FormsModule]
})
export class DialogNewScenarioComponent implements OnInit {
  @Input() id_projet!: number;
  @Input() status_accordion!: number;
  @Output() scenarioCreated = new EventEmitter<void>(); // Emittente per segnalare la creazione
  nameScenario: string = '';
  submitState: boolean = true;
  urlAPI = environment.apiHost;

  constructor(private _parent: ProjetPage, private auth: AuthentificationService) { }

  changeSubmitState() {
    this.submitState = this.nameScenario.length === 0;
  }

  async createNewScenario() {
    try {
      this._parent.accordionEnergieStatus = -1;
      const token = await this.auth.getToken();
      if (!token) {
        console.error('Token non disponible');
        return;
      }

      const headers: SSEHeaders = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      };

      const payload = JSON.stringify({
        "libelle": this.nameScenario
      });

      const options: SSEOptions = {
        headers: headers,
        payload: payload,
        method: 'POST',
        withCredentials: false,
        start: true,
        debug: true
      };

      const url = `${this.urlAPI}api/projet/${this.id_projet}/scenario`;
      const sse = new SSE(url, options);
      this.scenarioCreated.emit();

      sse.addEventListener('message', (e: any) => {
        if (e.data && e.data !== '') {
          const res = JSON.parse(e.data);
          console.log('Réponse:', res);
        }
      });

      sse.addEventListener('error', (e: any) => {
        console.error('Erreur lors de la connexion SSE:', e);
      });

      sse.stream();
    } catch (error) {
      console.error('Erreur lors de la requête:', error);
    }
  }

  ngOnInit() {}
}
