<ion-row class="pie-container">
  <ion-col *ngFor="let echartsOptions of echartsListBE3; index as i">
    <div class="graphe-conso" id="chart-container-{{idx_scenario}}-{{id_participant}}-{{i+1}}" echarts [options]="echartsOptions" (chartInit)="onChartInit($event)" (chartClick)="onChartClick($event)"></div>
  </ion-col>
</ion-row>
<ion-row style="margin-top: 5% !important;">
  <ion-col *ngFor="let echartsOptions of echartsListBE4; index as i">
    <div class="graphe-conso"  id="chart-container-{{idx_scenario}}-{{id_participant}}-{{i+4}}" echarts [options]="echartsOptions" (chartInit)="onChartInit($event)" (chartClick)="onChartClick($event)"></div>
  </ion-col>
</ion-row>
