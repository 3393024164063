import { CommonModule } from '@angular/common';
import { Component, Input, OnInit, effect } from '@angular/core';
import { 
  IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, 
  IonInput, IonItem, IonList, IonSelect, IonSelectOption, IonTitle, IonToggle, IonReorder, IonIcon, IonBadge } from "@ionic/angular/standalone";
 import { CdkDrag, CdkDragDrop, CdkDragPlaceholder, CdkDropList, moveItemInArray } from '@angular/cdk/drag-drop';
import {MatTabsModule} from '@angular/material/tabs';
import { ProjetPage } from 'src/app/Pages/projet/projet.page';
import { EntiteProjet, EntiteScenario, EntiteParticipant, EntiteProducteur, ListeParticipants, EntiteProducteurScenario } from 'src/app/services/EntiteProjet';
import { ProjetService } from 'src/app/services/projet.service';

@Component({
  selector: 'app-scenario-producteurs', 
  templateUrl: './scenario-producteurs.component.html',
  styleUrls: ['./scenario-producteurs.component.scss'],
  standalone: true,
  imports: [IonBadge, IonIcon, IonReorder, 
    CommonModule, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, 
    IonInput, IonItem, IonList, IonSelect, IonSelectOption, IonTitle, IonToggle,
    MatTabsModule, CdkDropList, CdkDrag, CdkDragPlaceholder
  ],
})
export class ScenarioProducteursComponent implements OnInit {

  @Input() idx_scenario!: number;
  sc!: EntiteScenario;
  disableOrdering: boolean = false;
  projet : EntiteProjet | null = null;
  mapProducteurs : Map<number, EntiteProducteurScenario> = new Map();
  producteursTries : ListeParticipants = [];

  constructor(protected _parent: ProjetPage, private ps: ProjetService) {
    this.projet = this._parent.projetCourant;

    effect( () => {   // Recharger le projetCourant sur la sollicitation du signal 
      const etat = this.ps.participantModifie();
      if (etat) {
        console.log('ScenarioProducteursComponent => Le signal participantModifie passe à TRUE !');
        this.projet = this._parent.projetCourant;
        if (this._parent.projetCourant?.scenarios[this.idx_scenario]) {
          this.sc = this._parent.projetCourant?.scenarios[this.idx_scenario];
          this.mapProducteurs.clear();
          this.sc.producteurs.forEach(p => this.mapProducteurs.set(p.idProducteur, p));
          this.producteursTries = this.filtreProducteursTriesParPriorite(this.sc.id);
          //console.log('Régénération de la liste des producteurs :', this.producteursTries);
        }
      }
    });
  }
  
  ngOnInit() {
    //console.log('ScenarioProducteursComponent::ngOnInit()');
    if (this._parent.projetCourant?.scenarios[this.idx_scenario]) {
      this.sc = this._parent.projetCourant?.scenarios[this.idx_scenario];
      this.sc.producteurs.forEach(p => this.mapProducteurs.set(p.idProducteur, p));
      this.producteursTries = this.filtreProducteursTriesParPriorite(this.sc.id);
    }
  }

  async ReordonneProducteurs(idScenario: number, ev: CdkDragDrop<string[]>) {
    window.getSelection()?.removeAllRanges();
    console.log('Ordre des producteurs :', this.prioritesProducteurs(idScenario));
    console.log('Element passe de la position ' + ev.previousIndex + ' vers ' + ev.currentIndex, ev);
    if (ev.previousIndex != ev.currentIndex) {
      let pp = this.prioritesProducteurs(idScenario);
      moveItemInArray(pp, ev.previousIndex, ev.currentIndex);
      // Transformer la liste des ID de particpants en liste d'ID de producteurs ...
      let producteurs: number[] = [];
      pp.forEach(part => {
        const participant = this._parent.mapParticipants.get(part);
        if (participant)
          producteurs.push(participant.producteur.id);
      });
      //console.log('Nouveau tableau des producteurs :', producteurs);
      const res = await this.ps.majDataScenario(this._parent.idProjet, idScenario, { priorite: producteurs });
      if (res && res.id > 0) {
        console.log('res :', res);
        this.sc = res;
        this.sc.producteurs.forEach(p => this.mapProducteurs.set(p.idProducteur, p));
        this._parent.mapScenarios.set(res.id, res);
        this.producteursTries = this.filtreProducteursTriesParPriorite(this.sc.id);
      }
    }
  }

  private findScenario(idScenario: number) : EntiteScenario | null {
    let res: EntiteScenario | null = null;
    this.projet?.scenarios.forEach(sc => {
      if (sc.id == idScenario)
        res = sc;
    });
    return res;
  }

  protected findParticipant(idParticipant: number) : EntiteParticipant | null {
    let res: EntiteParticipant | null = null;
    this.projet?.participants.forEach(p => {
      if (p.id == idParticipant)
        res = p;
    });
    return res;
  }

  private findParticipantProducteur(idProducteur: number) : EntiteParticipant | null {
    let res: EntiteParticipant | null = null;
    this.projet?.participants.forEach(p => {
      if (p && p.producteur && p.producteur.id == idProducteur)
        res = p;
    });
    return res;
  }

  private prioriteProducteur(idProducteur: number): number {
    const p = this.mapProducteurs.get(idProducteur);
    if (p)
      return p.prioriteRepartition;
    return -1;
  }

  private prioritesProducteurs(idScenario: number): number[] {
    let t: number[] = [];
    let tp: number[] = [];
    // Le tableau des ID de producteurs
    if (this.sc && this.sc.producteurs.length > 0) {
      this.sc.producteurs.forEach(p => {
        t.push(p.idProducteur) 
      });
    }
    //Trier les Id de prodcteurs en f() de la priorité définie
    t.sort( (a, b) => {
      const pa = this.prioriteProducteur(a);
      const pb = this.prioriteProducteur(b);
      //console.log('Comparer les priorités (a, b)', a, b, pa, pb);
      return pa-pb;
    });
    // Transformer le tableau d'ID de producteurs en tableau d'ID de participants
    t.forEach(id => {
      const p = this.findParticipantProducteur(id);
      if (p)
        tp.push(p.id);
    });
    // Rajouter Rajouter les éventuels producteurs ne figurant pas encore dans le scénario
    this.filtreProducteurs(idScenario).forEach(p => {
      if (! tp.find( id => { return p.id == id}))
        tp.push(p.id);
    });
    return tp;
   }

   private filtreProducteurs(idScenario: number) : ListeParticipants {
    let t : ListeParticipants = [];
    this.projet?.participants.forEach(p => {
      if (p.producteur && p.producteur.generateurs.length > 0) {
        t.push(p);
      }
    });
    return t;
   }

   prioriteParticipant(idParticipant: number): number {
    const pa = this.findParticipant(idParticipant);
    if (pa && pa.producteur && pa.producteur.id > 0)
      return this.prioriteProducteur(pa.producteur.id) + 1;
    return -1
   }

   filtreProducteursTriesParPriorite(idScenario: number) : ListeParticipants {
    let t : ListeParticipants = [];
    this.prioritesProducteurs(idScenario).forEach(id => {
      const p = this.findParticipant(id);
      if (p)  
        t.push(p);
    });
    return t;
   }
}
