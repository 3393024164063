/* eslint-disable @angular-eslint/no-empty-lifecycle-method */
import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, input, Input, OnInit, ViewChild } from '@angular/core';

import { IonContent, IonTitle, IonText, IonGrid, IonRow, IonCol, IonRouterLink, IonLabel, IonList, IonListHeader,
  IonItem, IonAccordionGroup, IonAccordion, IonButtons, IonButton, IonInput, IonSelect, IonSelectOption, IonIcon,
  IonCard, IonCardHeader, IonCardTitle, IonCardSubtitle, IonCardContent, ModalController, IonLoading,IonCheckbox, IonSegment, IonSegmentButton, IonToggle, ModalOptions, IonDatetime, IonModal, IonPopover, IonFabButton, IonFab, IonSpinner } from '@ionic/angular/standalone';
import { ConfigurationApiService } from 'src/app/services/configuration-api.service';
import { EntiteProducteurScenario, EntiteScenario } from 'src/app/services/EntiteProjet';
import { ProjetService } from 'src/app/services/projet.service';

@Component({
  selector: 'app-plan-financier-par-producteur',
  templateUrl: './plan-financier-par-producteur.component.html',
  styleUrls: ['./plan-financier-par-producteur.component.scss'],
  standalone: true,
  imports: [IonSpinner, IonFab, IonFabButton, IonPopover, IonModal,
    CommonModule, IonContent, IonTitle, IonText, IonGrid, IonRow, IonCol, IonRouterLink, IonButtons,
    IonLabel, IonList, IonListHeader, IonItem, IonAccordionGroup, IonAccordion, IonInput, IonSelect, IonSelectOption, IonButton, IonIcon,
   IonSegment, IonSegmentButton, IonLoading,IonCheckbox,
    IonCard, IonCardHeader, IonCardTitle, IonCardSubtitle, IonCardContent, IonToggle, IonDatetime]

})

export class PlanFinancierParProducteurComponent  implements OnInit, AfterViewInit {
  @Input() idx_scenario!: number;
  @Input() id_participant!: number;
  @Input() id_producteur!: number;
  @Input() scenario!: EntiteScenario;

  @ViewChild('capex') capex!: IonInput;
  @ViewChild('opex') opex!: IonInput;
  @ViewChild('taux_actualisation') taux_actualisation!: IonInput;
  @ViewChild('taux_emprunt') taux_emprunt!: IonInput;
  @ViewChild('part_apport') part_apport!: IonInput;
  @ViewChild('apport') apport!: IonInput;
  @ViewChild('duree_emprunt') duree_emprunt!: IonInput;
  @ViewChild('hausse_annuelle_OPEX') hausse_annuelle_OPEX!: IonInput;
  @ViewChild('IFER') IFER!: IonInput;
  @ViewChild('coef_degradation_performance') coef_degradation_performance!: IonInput;
  @ViewChild('duree_ammortissement') duree_ammortissement!: IonInput;

  @ViewChild('use_tarif_OA') use_tarif_OA!: IonCheckbox;
  @ViewChild('revalorisation_annuelle_OA') revalorisation_annuelle_OA!: IonInput;
  @ViewChild('tarif_achat_OA_VT') tarif_achat_OA_VT!: IonInput;
  @ViewChild('tarif_achat_OA_surplus') tarif_achat_OA_surplus!: IonInput;
  @ViewChild('use_complement_remuneration') use_complement_remuneration!: IonCheckbox;

  producteur! : EntiteProducteurScenario;

  constructor(private cas: ConfigurationApiService, private ps: ProjetService) { 
    //console.log("composant PF constructor");
  }

  ngOnInit() {
    // console.log("composant PF");
    // console.log("scenario", this.scenario);
    // // console.log("id_participant", this.id_participant);
    // console.log("id_producteur", this.id_producteur);
  }
  ngAfterViewInit() {
    this.scenario.producteurs.forEach( prod => {
      if(prod.idProducteur == this.id_producteur)
      {
        //console.log("this.taux_actualisation", this.taux_actualisation);
        //console.log("prod.financement.taux_actualisation", prod.financement.taux_actualisation);
        
        this.capex.value                  = prod.financement.CAPEX;
        this.opex.value                   = prod.financement.OPEX;
        this.taux_actualisation.value     = prod.financement.taux_actualisation;
        this.taux_emprunt.value           = prod.financement.taux_emprunt;
        this.duree_emprunt.value          = prod.financement.duree_emprunt;
        this.hausse_annuelle_OPEX.value   = prod.financement.hausse_annuelle_OPEX;
        this.IFER.value                   = prod.financement.IFER;
        this.part_apport.value            = prod.financement.part_apport;
        this.apport.value                 = (prod.financement.CAPEX * prod.financement.part_apport) / 100 ;
        this.coef_degradation_performance.value = prod.financement.coef_degradation_performance;
        this.duree_ammortissement.value   = prod.financement.duree_ammortissement;

        this.use_tarif_OA.checked                 = prod.revenus.use_tarif_OA;
        this.tarif_achat_OA_VT.value              = prod.revenus.tarif_achat_OA_VT;
        this.use_complement_remuneration.checked  = prod.revenus.use_complement_remuneration;
        this.revalorisation_annuelle_OA.value     = prod.revenus.revalorisation_annuelle_OA;
        this.tarif_achat_OA_surplus.value         = prod.revenus.tarif_achat_OA_surplus;
      }
    })
  }
  
  majApport(){
      this.apport.value = (<number>this.capex.value * <number>this.part_apport.value) / 100;
  }

  async enregistrerVariableFinancieres(){
    const data = {
      financement : {
        CAPEX: this.capex.value,
        OPEX: this.opex.value,
        taux_actualisation: this.taux_actualisation.value ,
        taux_emprunt: this.taux_emprunt.value,
        duree_emprunt: this.duree_emprunt.value,
        hausse_annuelle_OPEX: this.hausse_annuelle_OPEX.value,
        IFER : this.IFER.value,
        coef_degradation_performance : this.coef_degradation_performance.value,
        duree_ammortissement : this.duree_ammortissement.value,
        part_apport : this.part_apport.value
      },
      revenus : {
        use_tarif_OA : this.use_tarif_OA.checked,
        tarif_achat_OA_VT : this.tarif_achat_OA_VT.value,
        use_complement_remuneration : this.use_complement_remuneration.checked,
        revalorisation_annuelle_OA : this.revalorisation_annuelle_OA.value,
        tarif_achat_OA_surplus : this.tarif_achat_OA_surplus.value
      }
    };
    console.log('setVariableFinancieres -> data', data);

    this.ps.majDataScenarioProducteur(this.idx_scenario, this.id_producteur, data).then(res => {
      //this.taux_actualisation.value = res?.producteurs[0].financement.taux_actualisation;
      if (res)
        res.producteurs.forEach( prod => {
          if(prod.idProducteur == this.id_producteur)
          {
        console.log("enregistrerVariableFinancieres", res);
      }});
    });
  }
}
