<ion-header>
  <ion-toolbar>
    <ion-title>{{titreModal}}</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="fermeModal()" [strong]="true">Fermer</ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content class="ion-padding">
  <div>
    <ion-segment mode="ios" slot="start" class="primary" value="tout" (ionChange)="afficheGraphe($event)">
      <ion-segment-button color="primary" value="tout">Tout</ion-segment-button>
      <ion-segment-button color="primary" value="semaine">Lundi à Vendredi</ion-segment-button>
      <ion-segment-button color="primary" value="weekend">Weekend</ion-segment-button>
      <ion-segment-button color="primary" value="samedi">Samedi</ion-segment-button>
      <ion-segment-button color="primary" value="dimanche">Dimanche</ion-segment-button>
    </ion-segment>
  </div>
  <!-- <div *ngIf="aucuneDonneeDisponible"><strong>Aucune donnée disponible !</strong></div> -->
  <div echarts [options]="echartsOptions" (chartInit)="onChartInit($event)" (chartClick)="onChartClick($event)" style="height: 1000px;width: 1000px;"></div>
</ion-content>