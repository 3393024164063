import { Component, Input, OnInit, ViewChild, ElementRef } from '@angular/core';
import {
  DatasetComponent,
  DatasetComponentOption,
  TransformComponent
} from 'echarts/components';
import { PieChart, PieSeriesOption } from 'echarts/charts';
import { LabelLayout } from 'echarts/features';
import { CanvasRenderer } from 'echarts/renderers';
import { ECharts, EChartsOption, dataTool } from 'echarts';
import { NgxEchartsDirective, provideEcharts } from 'ngx-echarts';
import { ProjetPage } from '../../Pages/projet/projet.page';
import { ProjetService } from 'src/app/services/projet.service';
import { partition } from 'rxjs';
import { ListeScenarios, ParticipantBilanEnergetique, Repartition, RepartitionParticipant } from 'src/app/services/EntiteProjet';
import { NgFor, NgForOf } from '@angular/common';
import { IonRow, IonCol, IonGrid } from "@ionic/angular/standalone";
import { ZRColor } from 'echarts/types/dist/shared';

@Component({
  selector: 'app-graphes-bilan-consommateur',
  templateUrl: './graphes-bilan-consommateur.component.html',
  styleUrls: ['./graphes-bilan-consommateur.component.scss'],
  standalone: true,
  imports: [IonGrid, IonCol, IonRow, 
    NgxEchartsDirective,
    NgForOf
  ],
  providers: [ provideEcharts() ]
})

export class GraphesBilanConsommateurComponent  implements OnInit {

  @Input() idx_scenario!: number; // Déclaration index scenario 
  @Input() id_participant!: number;// Déclaration id participant pou controle a l'affichage
  @ViewChild(`chart-container-$idx_scenario-$id_participant-1`) div1 !: ElementRef;


  // Déclaration et initialisation de la liste des options de graphiques ECharts
  echartsListBE3: EChartsOption[] = [];
  echartsListBE4: EChartsOption[] = [];
  
  constructor(private _parent: ProjetPage, private ps: ProjetService) { } // Injection du composant de la page projet parente

  // Méthode d'initialisation du composant
  ngOnInit() {
    //console.log('ngOnInit', this.div1);
    this.initialiseGraphes();
  }

  private initialiseGraphes() {
    const projet = this._parent.projetCourant; // Accès au projet courant du parent
    if (projet) { // Si un projet parent est défini
      const scenarioCourant = projet.scenarios; // Accès aux scénarios du projet
      const participants = scenarioCourant[this.idx_scenario].bilanEnergetique.indicateurs.participants; // Accès aux participants du scénario courant
      participants.forEach((participant: ParticipantBilanEnergetique) => { // Pour chaque participant
        if(participant.id == this.id_participant) { // Si l'ID du participant correspond
          let repartition_soutirage = participant.repartition_tarifaire.soutirage; // Accès à la répartition de soutirage du participant
          if(repartition_soutirage && repartition_soutirage.length > 0 && repartition_soutirage[0].horosaison != "UNDEFINED") { // Si la répartition est définie et valide
            this.afficheGraphesBE3(repartition_soutirage); // Affichage des graphiques basés sur la répartition
            this.afficheGraphesBE4(participant.TAP, participant.energie_alloue, participant.total_conso, participant.energie_alloue_acc, participant.energie_alloue_aci); // Affichage des graphiques basés sur la répartition
          }
        }
      });
    }
  }
  
  onChartInit(ec: any) { // Méthode appelée à l'initialisation du graphique
    //console.log('onChartInit()', ec.id);
  }

  onChartClick(ev: any) { // Méthode appelée lors d'un clic sur le graphique
    //console.log('onChartClick', ev);
  }

  async afficheGraphesBE3(repartitions: RepartitionParticipant[]){ // Méthode asynchrone pour afficher les graphiques
    let formatteNombre: any = this.ps.formatteNombre;
    if(repartitions.length <= 2){ // Si le nombre de répartitions est inférieur ou égal à 2
      let listSources: Array<{ value: number; name: string }> = []; // Initialisation de la liste des sources de données
      repartitions.forEach((repartition: RepartitionParticipant) => { // Pour chaque répartition
        listSources.push({value: repartition.total, name: repartition.horosaison}); // Ajout de la répartition à la liste des sources
      });
      let echartsOptions:EChartsOption = { // Configuration des options du graphique ECharts
        title: {
          text: 'Plages horaires',
          left: 'center'
        },
        tooltip: {
          trigger: 'item',
          formatter: function(params: any) {
            let res = params.data.name + '<br/>' + formatteNombre(params.data.value, 0) + ' kWh';
            return res;
          }
        },
        legend: {
          show: true,
          left: 'center',
          top: 'bottom'
        },
        series: [
          {
            type: 'pie',
            radius: '65%',
            data: listSources,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ],
        label:{
          show: true,
          formatter: '{b} \n\n {d} %',
          fontSize: 15
        }
      };
      let Mycolor: Array<ZRColor> = []; // Initialisation de la liste des couleurs
      listSources.forEach((sourceObj) => { // Pour chaque source de données
        if(sourceObj.name == "BASE"){ 
          Mycolor.push("#fc9c62");
        }else if(sourceObj.name == "HC"){ 
          Mycolor.push("#fc9c62");
        }else if(sourceObj.name == "HP"){ 
          Mycolor.push("#fc6103");
        }
      });
      echartsOptions.color = Mycolor; // Attribution des couleurs au graphique
      this.echartsListBE3.push(echartsOptions); // Ajout des options du graphique à la liste
    }else { // Si le nombre de répartitions est supérieur à 2
      let listSources: Array<Array<{ value: number; name: string }>> = [[], [], []]; // Initialisation de la liste des sources de données avec trois catégories
      repartitions.forEach((repartition) => {
        // Traitement des données pour chaque catégorie de sources
        // Pour listSources[0]
        let found = false;
        listSources[0].forEach((sourceObj) => {
          if (sourceObj.name === repartition.saison) {
            sourceObj.value += repartition.total;
            found = true;
          }
        });
        if (!found && repartition.saison !== "POINTE") {
          listSources[0].push({ value: repartition.total, name: repartition.saison });
        }
        // Pour listSources[1]
        found = false;
        listSources[1].forEach((sourceObj) => {
          if (sourceObj.name === repartition.horosaison) {
            sourceObj.value += repartition.total;
            found = true;
          }
        });
        if (!found) {
          listSources[1].push({ value: repartition.total, name: repartition.horosaison });
        }
        // Pour listSources[2]
        found = false;
        listSources[2].forEach((sourceObj) => {
          if (sourceObj.name === repartition.periode) {
            sourceObj.value += repartition.total;
            found = true;
          }
        });
        if (!found) {
          listSources[2].push({ value: repartition.total, name: repartition.periode });
        }
      });
      for(let i:number = 0; i < 3; i++){ // Pour chaque catégorie de sources
        let showLegend: boolean = true; // (i === 0) ? false : true; // Affichage de la légende
        let echartsOptions: EChartsOption = { // Configuration des options du graphique ECharts
          title: {
            text: ((i == 0) ? "Saisons" : (i == 1) ? "Horosaisons" : "Plages horaires"),
            left: 'center'
          },
          tooltip: {
            trigger: 'item',
            formatter: function(params: any) {
              let res = params.data.name + '<br/>' + formatteNombre(params.data.value, 0) + ' kWh';
              return res;
            }
          },
          legend: {
            show: showLegend,
            left: 'center',
            top: 'bottom'
          },
          series: [
            {
              type: 'pie',
              radius: '60%',
              data: listSources[i],
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: 'rgba(0, 0, 0, 0.5)'
                }
              }
            }
          ],
          label:{
            show: true,
            formatter: '{b} \n\n {d} %',
            fontSize: 13
          }
        };
        let Mycolor: Array<ZRColor> = []; // Initialisation de la liste des couleurs
        listSources[i].forEach((sourceObj) => { 
          if(sourceObj.name == "HIVER"){ 
            Mycolor.push("#b7e6f8");
          }else if(sourceObj.name == "ETE"){ 
            Mycolor.push("#f7d35b");
          }else if(sourceObj.name == "POINTE"){ 
            Mycolor.push("#c54b03");
          }else if(sourceObj.name == "HCH"){ 
            Mycolor.push("#e0f7ff");
          }else if(sourceObj.name == "HPH"){ 
            Mycolor.push("#02a9e3");
          }else if(sourceObj.name == "HPE"){ 
            Mycolor.push("#e6b302");
          }else if(sourceObj.name == "HCE"){ 
            Mycolor.push("#fce9a7");
          }else if(sourceObj.name == "HC"){ 
            Mycolor.push("#fc9c62");
          }else if(sourceObj.name == "HP"){ 
            Mycolor.push("#fc6103");
          }
        });
        echartsOptions.color = Mycolor; // Attribution des couleurs au graphique
        this.echartsListBE3.push(echartsOptions); // Ajout des options du graphique à la liste
      }    
    }
  }
  async afficheGraphesBE4(percentTap:number, energieAlloue:number, totalConso:number, energieAlloueACC:number, energieAlloueACI:number){ // Méthode asynchrone pour afficher les graphiques
    let formatteNombre: any = this.ps.formatteNombre;
    let echartsOptions: EChartsOption = { // Configuration des options du graphique ECharts
        title: {
          text: 'Taux autoproduction',
          left: 'center',
          top: 'top'
        },
        series: [
          {
            type: 'gauge',
            progress: {
              show: true,
              width: 18
            },
            axisLine: {
              lineStyle: {
                width: 18
              }
            },
            axisTick: {
              show: false
            },
            splitLine: {
              length: 15,
              lineStyle: {
                width: 2,
                color: '#999'
              }
            },
            axisLabel: {
              distance: 25,
              color: '#999',
              fontSize: 10
            },
            anchor: {
              show: true,
              showAbove: true,
              size: 25,
              itemStyle: {
                borderWidth: 10
              }
            },
            title: {
              show: true
            },
            detail: {
              valueAnimation: false,
              fontSize: 20,
              offsetCenter: [0, '55%'],
              formatter: '{value} %'
            },
            data: [
              {
                value: +percentTap.toFixed(2)
              }
            ],
          }
        ]
    };
    this.echartsListBE4.push(echartsOptions); // Ajout des options du graphique à la liste
  echartsOptions = { // Configuration des options du graphique ECharts
    title: {
      text: 'Consommation totale',
      left: 'center'
    },
    tooltip: {
      trigger: 'item',
      formatter: function(params: any) {
        let res = params.data.name + '<br/>' + formatteNombre(params.data.value, 0) + ' kWh';
        return res;
      }
    },
    legend: {
      show: false,
      left: 'center',
      top: 'bottom'
    },
    series: [
      {
        type: 'pie',
        radius: '65%',
        data: [{value: energieAlloueACC, name: 'ACC'},{value: energieAlloueACI, name: 'ACI'}, {value: totalConso - energieAlloue, name: "Appoint"}],
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)'
          }
        }
      }
    ],
    label:{
      show: true,
      formatter: '{b} \n\n {d} %',
      fontSize: 15
    }
  };
  let data = [{value: energieAlloueACC, name: 'ACC'},{value: energieAlloueACI, name: 'ACI'}, {value: totalConso - energieAlloue, name: "Appoint"}];
  let myColor: Array<ZRColor> = []; // Initialisation de la liste des couleurs
  data.forEach((sourceObj) => { // Pour chaque source de données
    if(sourceObj.name == "ACC"){ 
      myColor.push("#0208bd");
    }else if(sourceObj.name == "ACI"){ 
      myColor.push("#0377fc");
    }else if(sourceObj.name == "Appoint"){ 
      myColor.push("#c54b03");
    }
  });
  echartsOptions.color = myColor; // Attribution des couleurs au graphique
  this.echartsListBE4.push(echartsOptions); // Ajout des options du graphique à la liste
  }
}