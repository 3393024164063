<ion-accordion-group>
  <ion-accordion value="first">
    <ion-item slot="header" color="light">
      <ion-label>Extrapolation</ion-label>
    </ion-item>
    <div class="ion-padding" slot="content">
      <ion-list>
        <ion-item>
          <ion-grid>
            <ion-row>
              <ion-col size="4">Début période</ion-col>
              <ion-col size="4">Fin période</ion-col>
              <ion-col size="4">Energie (kWh)</ion-col>
            </ion-row>
          </ion-grid>
        </ion-item>
        <liste-saisie #liste_saisie>
        </liste-saisie>
        <ion-item>
          <ion-button (click)="ajoutePeriode($event)" class="bfl">Nouvelle période</ion-button>
          <ion-button (click)="extrapolation($event)" class="bfr" color="success">lancer l'extrapolation</ion-button>
        </ion-item>
        <ion-item class="ion-text-center" *ngIf="etatUpload !== '...'" [color]="couleurMsg">{{etatUpload}}</ion-item>
      </ion-list>
    </div>
  </ion-accordion>
</ion-accordion-group>



