/* eslint-disable @angular-eslint/no-empty-lifecycle-method */
import { Component, OnInit } from '@angular/core';
import { CommonModule, LocationStrategy } from '@angular/common';
import { Router } from '@angular/router';
import { IonContent, IonButton, IonNavLink, IonLabel } from '@ionic/angular/standalone';
import { environment } from 'src/environments/environment';
import { AuthentificationService } from 'src/app/services/authentification.service';
import { ProjetService } from '../../services/projet.service';
import { EntiteProjet } from 'src/app/services/EntiteProjet';

@Component({
  selector: 'app-home',
  templateUrl: 'home.page.html',
  styleUrls: ['home.page.scss'],
  standalone: true, 
  imports: [CommonModule, IonContent, IonButton, IonNavLink, IonLabel ],
})

export class HomePage implements OnInit {


  LocationStrategy!: LocationStrategy;
  constructor(private auth: AuthentificationService, private router : Router, private location : LocationStrategy, private ps: ProjetService) {
    
  }

  ngOnInit(): void {
  }

  EnvironnementProd() : boolean {
    return environment.production;
   }

   ionViewDidEnter() {
    this.auth.headerTitreSignal.set('Tecsol PV - Accueil');
    this.auth.menuLateralSignal.set('lg');
  }
  testFormatNumerique(valeur: string, precision: number) {
    //const s = this.ps.formatteNombre(valeur, precision);
    //console.log(s.split("").map(c => c.charCodeAt(0).toString(16).padStart(2, "0")).join(" "));
    return this.ps.formatteNombre(valeur, precision);
  }

  goBack() {
    this.location.back();
  }
  gotoProjets() {
    this.router.navigateByUrl('/projet');
  }
}
