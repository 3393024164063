import { HttpRequest, HttpInterceptorFn, HttpHandlerFn, HttpErrorResponse } from '@angular/common/http';
import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AlertController } from '@ionic/angular';

declare global {
     function msgAlerte(alert: AlertController, titre: string, sujet: string, msg: string) : Promise<void>;
}

globalThis.msgAlerte = async (alert: AlertController, titre: string, sujet: string, msg: string) => {
    const al = await alert.create({
      header: titre,
      subHeader: sujet,
      message: msg,
      buttons: [{
        text: 'Fermer',
        handler: data => {
          //console.log('Alerte refermée.');
        }
      }]
    });
    al.present();
  }

export const ApiErrorInterceptor: HttpInterceptorFn = (req: HttpRequest<unknown>, next: HttpHandlerFn) => {
  const router: Router = inject(Router);
  const alert: AlertController = inject(AlertController);
  return next(req).pipe(
    catchError((err: HttpErrorResponse) => {
      let redirect = false;
      let sujet = 'Requête API : ' + err.url;
      let msg = '';
      switch (err.status) {
        case 0:
          console.log("ApiErrorInterceptor => Network error");
          msg = "Erreur réseau. Veuillez vérifier votre connexion internet : " + err.message;
          break;
        case 400:
          console.log("ApiErrorInterceptor => 400 Bad Request. Server cannot process the request");
          msg = "Requête erronée : le serveur ne peut traiter votre demande : " + err.message;
          break;
        case 401:
          console.log("ApiErrorInterceptor => 401 Authentication Error");
          msg = "La session courante a expiré ou vous avez été déconnecté.";
          redirect = true;
          break;
        case 500:
          console.log("ApiErrorInterceptor => 500 Internal Server Error");
          msg = "Une erreur du serveur est intervenue lors du traitement de la requête :\n" + err.message;
          break;
        default:
          console.log("ApiErrorInterceptor => " + err.url);
          console.log("ApiErrorInterceptor => " + err.status + ", " + err.statusText);
          msg = "Une erreur est intervenue lors du traitement de la requête :" + err.message;
          break;
      }
      msgAlerte(alert, 'Erreur API', sujet, msg);
      if (redirect)
        router.navigateByUrl("/connexion");
      return throwError(() => err);
    })
  );
}

