import { Component, Input, ViewChild, ElementRef, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonGrid, IonRow, IonCol, IonLabel, IonList, IonItem, IonInput, IonIcon, IonModal, IonDatetime, IonDatetimeButton } from '@ionic/angular/standalone';
import { addIcons } from 'ionicons';
import { trash } from 'ionicons/icons';

export type DataLigneExtrapolation = {
  start: string | string[],
  end: string | string[],
  energy: number
}

@Component({
  selector: 'app-ligne-saisie',
  templateUrl: './ligne-saisie.component.html',
  styleUrls: ['./ligne-saisie.component.scss'],
  standalone: true,
  imports: [
    CommonModule,  IonGrid, IonRow, IonCol, IonLabel, IonList, IonItem, IonInput, IonIcon, IonModal, IonDatetime, IonDatetimeButton ]
})
export class LigneSaisieComponent  implements OnInit {
  @Input() num !: number;
  @ViewChild('deb') deb!: IonDatetime;
  @ViewChild('fin') fin!: IonDatetime;
  @ViewChild('energy') energy!: ElementRef;
  supprime: boolean = false;
  dateDebOk: string = 'warning';
  dateFinOk: string = 'warning';
  numLigne: number = 0;
  debValue: string;
  finValue: string;
  energie: number = 0;

  constructor() {
    addIcons({ trash });
    const d = new Date().toISOString();
    console.log(d);
    this.debValue = d;
    this.finValue = d;
   }

  ngOnInit() {
    console.log('InitComponent');
  }

  getValues(): DataLigneExtrapolation {
    let v: DataLigneExtrapolation = {start: '', end: '', energy: 0};
    if (this.supprime)
      return v;
    if (this.deb.value && typeof(this.deb.value) == 'string')
      v.start = (this.deb.value != '') ? this.deb.value.substring(0, 10).concat(" 00:00:00") : this.deb.value;
    if (this.fin.value && typeof(this.fin.value) == 'string')
      v.end = (this.fin.value != '') ? this.fin.value.substring(0, 10).concat(" 23:50:00") : this.fin.value;
    if (this.energy)
      v.energy = parseInt(this.energy.nativeElement.value);
    return v;
  }

  supprimePeriode(ev: MouseEvent) {
    this.supprime = true;
  }

  dateSel(ev: CustomEvent) {
    console.log(ev);
    const el = ev.target as HTMLElement;
    if (el.id == 'datetime1') {
      if (ev.detail != '')
        this.dateDebOk = 'success';
      else
       this.dateDebOk = 'warning';
    }
    else {
      if (ev.detail != '')
        this.dateFinOk = 'success';
      else
       this.dateFinOk = 'warning';
    }
  }
}
