import { Component, Input, OnInit } from '@angular/core';
import { UploadFileComponent } from '../../composants/upload-file/upload-file.component';
import { IonCard, IonCardHeader, IonCardContent, IonContent, IonHeader, IonToolbar, IonButtons, IonButton, IonTitle, ModalController } from '@ionic/angular/standalone';

@Component({
  selector: 'app-dialogue-import-projet',
  templateUrl: './dialogue-import-projet.component.html',
  styleUrls: ['./dialogue-import-projet.component.scss'],
  imports: [UploadFileComponent, IonCard, IonCardHeader, IonCardContent, IonContent, IonHeader, IonToolbar, IonButton, IonButtons, IonTitle],
  standalone: true,
})
export class DialogueImportProjetComponent  implements OnInit {
  @Input() idProjet!: number;
  constructor(private modalCtrl: ModalController) { }

  ngOnInit() {}
  async annuleImportProjet(event: MouseEvent) {
    await this.modalCtrl.dismiss(null, 'annule');
   }
}
