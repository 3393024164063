import { Component, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonContent, IonHeader, IonInput, IonToolbar, IonTitle, IonCard, IonCardHeader, IonCardTitle, IonCardContent,
  IonItem, IonGrid, IonRow, IonCol, IonButton
 } from '@ionic/angular/standalone';
import { AuthentificationService } from '../../services/authentification.service';
import { effect } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-connexion',
  templateUrl: './connexion.page.html',
  styleUrls: ['./connexion.page.scss'],
  standalone: true,
  imports: [CommonModule, FormsModule,
    IonContent, IonHeader, IonInput, IonToolbar, IonTitle, IonCard, IonCardHeader, IonCardTitle, IonCardContent,
    IonItem, IonGrid, IonRow, IonCol, IonButton
  ]
})
export class ConnexionPage implements OnInit {
  @ViewChild('login') login!: IonInput;
  @ViewChild('passwd') passwd!: IonInput;
  VERSION : string = environment.VERSION;
  constructor(private auth: AuthentificationService, private router : Router) {}

  ngOnInit() {
    console.log('InitComponent');
  }

  onKeyEnter(event: Event) {
    const login = this.login.value ? `${this.login.value}` : "";
    const passwd = this.passwd.value ? `${this.passwd.value}` : "";
    if (login != '' && passwd != '')
      this.connexion();
  }

  connexion(){
    let login = this.login.value ? `${this.login.value}` : "";
    let passwd = this.passwd.value ? `${this.passwd.value}` : "";

    this.auth.logIn(login, passwd).subscribe();
  }
}
