export type ReponseApi = {
  statut: string;
  message?: string;
  details?: string;
  reponse?: any;
}

export enum TypeProjet {
  'VT' = 'Vente Réseau',
  'ACT' = 'Autoconsommation Individuelle Totale (sans surplus)',
  'ACI' = 'Autoconsommation Individuelle avec vente du surplus',
  'ACC' = 'Autoconsommation Collective'
};

export enum TypeDocument {
  'production' = 'production',
  'conso' = 'consommation',
  'projet' = 'projet'
};

export enum Repartition {
  'STATIQUE' = 'Statique',
  'DYN_DEF' = 'Dynamique par défaut',
  'FULL_DYN' = 'Full dynamique'
};

export type EntiteProjet = {
  id: number;
  libelle: string;
  type: TypeProjet;
  nbConsommateurs: number;
  scenarios: ListeScenarios;
  documents: ListeDocuments;
  participants: ListeParticipants;
  dispositifs: ListeDispositifs;
  courbes: ListeCourbes;
};
export type ListeProjets = EntiteProjet[];

export type EntiteDocument = {
  id: number;
  type: TypeDocument;
  format: string;
  nom: string;
  listeCourbes: number[];
};
export type ListeDocuments = EntiteDocument[];

export type EntiteCourbe = {
  id: number;
  grandeur: string;
  idDispositif: number;
  idDocument: number;
};

export type ListeCourbes = EntiteCourbe[];

// export type EntiteDonneesCourbe = {
//   statut: string;
//   reponse: number[];
// };
export type EntiteParticipant = {
  id: number;
  nom: string;
  producteur: EntiteProducteur;
  consommateur: EntiteConsommateur;
};
export type ListeParticipants = EntiteParticipant[];

export type EntiteProducteur = {
  id: number;
  nbGenerateurs: number;
  puissanceSouhaitee: number;
  generateurs: ListeGenerateurs;
  priorite: number;
};
export type ListeProducteurs = EntiteProducteur[];

export type EntiteConsommateur = {
  id: number;
  saison: string;
  decoupageHoraire: string;
  compteurs: ListeCompteurs;
};
export type ListeConsommateurs = EntiteConsommateur[];

export type EntiteGenerateur = {
  id: number;
  type: string;
  puissance: number;
  document: string;
  idDocument: number;
  code: string;
};
export type ListeGenerateurs = EntiteGenerateur[];

export type PlageTarifairePeriode = {
  deb: string;
  fin: string;
}

export type PlageTarifairePlagePointe = {
  deb: string;
  fin: string;
  horosaison: 'POINTE';
  plage: 'POINTE';
  jours: number[]
}

export type PlageTarifairePlageHC = {
  deb: string;
  fin: string;
  horosaison: 'BASE' | 'HC' | 'HP' | 'HCH' | 'HPH' | 'HCE' | 'HPE' | 'POINTE';
  plage: 'HC' | 'HP' | 'HCH' | 'HPH' | 'HCE' | 'HPE' | 'POINTE';
}

export type PlageTarifairePlageDefault = {
  default: boolean;
  horosaison: 'BASE' | 'HC' | 'HP' | 'HCH' | 'HPH' | 'HCE' | 'HPE' | 'POINTE';
  plage: 'BASE' | 'HC' | 'HP' | 'HCH' | 'HPH' | 'HCE' | 'HPE' | 'POINTE';
}

export type PlageTarifairePlage = PlageTarifairePlagePointe | PlageTarifairePlageHC | PlageTarifairePlageDefault;

export type PlageTarifaire = {
  libelle: string;
  saison: string;
  periodes: PlageTarifairePeriode[];
  plages: PlageTarifairePlage[];
}

export type EntiteCompteur = {
  id: number;
  type: string;
  puissance: number;  // Saisie puissance souscrite
  code: string;
  document: string;
  plage_tarifaire: PlageTarifaire[];
  tarif_fourniture: {};
  config_turpe: {
    categorie_puissance: string;
    sous_categorie_puissance: string;
    puissances_soucrites: {};
  }; 
  accise : number;
  CTA: number;
  TVA: number;
};
export type ListeCompteurs = EntiteCompteur[];

export type ListeDispositifs = Array<EntiteCompteur | EntiteGenerateur>;

export type EntiteScenario = {
  id: number;
  libelle: string;
  repartitionProd: string;
  repartitionConso: string;
  typeRepartition: 'STATIQUE' | 'DYN_DEF' | 'FULL_DYN';
  bilanEnergetique: EntiteBilanEnergetique;
  bilanFacturation: EntiteBilanFacturation;
  bilanFinancier: EntiteBilanFinancier;
  compteurs: ListeCompteursScenario;
  generateurs: ListeGenerateursScenario;
  producteurs: ListeProducteursScenario;
  sorties: ListeSortiesScenario;
};
export type ListeScenarios = EntiteScenario[];

export type EntiteCompteurScenario = {
  actif: boolean;
  idParticipant: number;
  idCompteur: number;
}
export type ListeCompteursScenario = EntiteCompteurScenario[];

export type EntiteGenerateurScenario = {
  actif: boolean;
  idParticipant: number;
  idGenerateur: number;
  pcSouhait: number;
  pOndSouhait: number;
  pcOptMin: number;
  pcOptMax: number;
  pcOptDelta: number;
}
export type ListeGenerateursScenario = EntiteGenerateurScenario[];

export type EntiteProducteurScenario = {
  idProducteur: number;
  detailRepartition: number[];
  prioriteRepartition: number;
  financement : {
    CAPEX: number,
    OPEX: number,
    taux_actualisation:   number ,
    taux_emprunt:  number,
    duree_emprunt:   number,
    hausse_annuelle_OPEX:   number,
    IFER : number,
    part_apport : number,
    coef_degradation_performance: number,
    duree_ammortissement : number
  },
  revenus : {
    use_tarif_OA : boolean,
    tarif_achat_OA_VT : number,
    use_complement_remuneration : boolean,
    revalorisation_annuelle_OA:   number,
    tarif_achat_OA_surplus:   number
  }
}
export type ListeProducteursScenario = EntiteProducteurScenario[];

export type EntiteSortieScenario = {
  idParticipant: number;
  id: number;
  grandeur: string;
  points: number;
}
export type ListeSortiesScenario = EntiteSortieScenario[];

export type RepartitionParticipant = {
  horosaison: string;
  periode: string; 
  saison: string;
  total: number;
}
export type RepartitionParticipantProd = {
  id_consommateur: number;
  energie: number;
  part: number;
}

export type ParticipantBilanEnergetique = {
  TAP: number;
  energie_alloue: number;
  energie_alloue_acc: number;
  energie_alloue_aci: number;
  fourniture: number;
  id: number;
  part_conso: number;
  part_prod: number;
  total_conso: number;
  total_prod: number;
  surplus_acc: number;
  repartition_prod: RepartitionParticipantProd[];
  repartition_tarifaire: {
    conso: RepartitionParticipant[];
    soutirage: RepartitionParticipant[];
  };
  repartition_turpe: [];
}

export type EntiteBilanEnergetique = {
  date: string;
  indicateurs: {
    global: {
      TAC: number;
      puissance_crete: number;
      TAP: number;
      excedent: number;
      part_excedent: number;
      total_autoconso: number;
      total_conso: number;
      total_injection: number;
      total_prod: number;
    };
    participants: ParticipantBilanEnergetique[];
  };
  type: string;
  version: string;
};

export type DetailFacturation = {
  base : number;
  coef: number;
  montant: number;
};

export type HypotheseFacturation = {
  energie_consommee: {
    allo: number;
    ACI: number;
    ACC: number;
  };
  fourniture: { 
    montant: number;
    details: {
      details: {
        HCB : DetailFacturation;
        HPB: DetailFacturation;
        HCH: DetailFacturation;
        HPH: DetailFacturation;
        POINTE: DetailFacturation;
      }
    }
  };
  montant: {
    HTVA: number;
    TVA: number;
    TTC: number;
  };
  taxes: {
    montant: number;
    details: {
      accise: {
        base : number;
        coef: number;
        montant: number;
        details : {
          allo : DetailFacturation;
          auto : DetailFacturation;
        }
      };
      CTA: DetailFacturation;
    }
  };
  turpe: {
    montant: number;
    details: {
      CGC: {
        montant: number;
        details: {
          CC: number;
          GC: number;
        }
      };
      CSF: {
        montant: number;
        details: {
          HCB : DetailFacturation;
          HPB: DetailFacturation;
          HCH: DetailFacturation;
          HPH: DetailFacturation;
          POINTE: DetailFacturation;
        }
      };
      CSV: {
        standard: {
          montant: number;
          details: {
            HCB : DetailFacturation;
            HPB: DetailFacturation;
            HCH: DetailFacturation;
            HPH: DetailFacturation;
            POINTE: DetailFacturation;
          }
        };
        allo: {
          montant: number;
          details: {
            HCB : DetailFacturation;
            HPB: DetailFacturation;
            HCH: DetailFacturation;
            HPH: DetailFacturation;
            POINTE: DetailFacturation;
          }
        };
        auto: {
          montant: number;
          details: {
            HCB : DetailFacturation;
            HPB: DetailFacturation;
            HCH: DetailFacturation;
            HPH: DetailFacturation;
            POINTE: DetailFacturation;
          }
        };
      }
    }
  }
}

export type ParticipantBilanFacturation = {
  id_participant: number;
  avec_solaire: HypotheseFacturation;
  sans_solaire: HypotheseFacturation;
}

export type EntiteBilanFacturation = {
  date: string;
  type: string;
  version: string;
  indicateurs: ParticipantBilanFacturation[];
}
export type EntiteBilanFinancier = {
  date: string;
  type: string;
  version: string;
  indicateurs: ParticipantBilanFinancier[];
}

export type ParticipantBilanFinancier = {
  id_participant: number;
  indicateurs: {
    ROI : {
      CA_actualise : number;
      TRB : number;
      taux_actualisation : number;
      resultat_avant_impots : number;
      TRI : number;
      VAN : number;
      TRA : number;
      TEC : number;
    },
    bilan_eco : {
      CAPEX_net_subvention : number;
      CEX_moyen : number;
      LCOE : number;
      PEX_moyen : number;
      duree_ammortissement : number;
      energie_produite : number;
    },
    cumul : {
      CA : number;
      OPEX : number;
      autre_revenu_fixe : number;
      complement_remuneration_autoconso : number;
      consommation_energie : number;
      economies_investisseur : number;
      energie_autoconsommee : number;
      prime_investissement : number;
      production_denergie_ac : number;
      vente_excedent_tarif_aggregateur : number;
      vente_excedent_tarif_reglemente : number;
      vente_prod_solaire_aux_autoconsommateurs : number;
    },
    cumul_pc : {
      autre_revenu_fixe : number;
      complement_remuneration_autoconso : number;
      economies_investisseur : number;
      prime_investissement : number;
      vente_excedent_tarif_aggregateur : number;
      vente_excedent_tarif_reglemente : number;
      vente_prod_solaire_aux_autoconsommateurs : number;
    },
    details : {
      CA : number;
      an : number;
      autre_revenu_fixe : number;
      complement_remuneration_autoconso : number;
      consommation_energie : number;
      economies_investisseur : number;
      energie_autoconsommee : number;
      prime_investissement : number;
      production_denergie_ac : number;
      tarif_achat_vente_surplus : number
      vente_excedent_tarif_aggregateur : number;
      vente_excedent_tarif_reglemente : number;
      vente_prod_solaire_aux_autoconsommateurs : number;
    }[],
    parametres : {
      consommation_annuelle_cumulee : number;
      degradation_performance : number;
      energie_autoconsommee : number;
      energie_pv_produite : number;
      hausse_prix_electricite : number;
      taux_autoconsommation : number;
      valorisation_energie_pv : number;
    }
  };
}