/* eslint-disable @angular-eslint/no-empty-lifecycle-method */
/* eslint-disable @angular-eslint/component-class-suffix */
/* eslint-disable @angular-eslint/component-selector */
import { Component, Input, ViewChild, ViewChildren, ViewContainerRef, OnInit, effect } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonGrid, IonRow, IonCol, IonLabel, IonList, IonItem, IonInput, IonButton, IonIcon, 
  IonModal, IonAccordion, IonAccordionGroup } from '@ionic/angular/standalone';

import { SSE, SSEHeaders, SSEOptions } from 'sse.js';
import { environment } from 'src/environments/environment';
import { AuthentificationService } from 'src/app/services/authentification.service';
import { ProjetService } from 'src/app/services/projet.service';
import { DataLigneExtrapolation, LigneSaisieComponent } from './ligne-saisie/ligne-saisie.component';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'liste-saisie',
  template: '',
  standalone: true,
  imports: [ LigneSaisieComponent ]
})

// eslint-disable-next-line @angular-eslint/component-class-suffix
export class ListeSaisie implements OnInit {
  //@ViewChildren(LigneSaisieComponent) lignes!: LigneSaisieComponent[];  // Ne semble être pas mis à jour lors de la création dynamique ???
  private lignesAjoutees: LigneSaisieComponent[] = [];

  constructor(private viewContainer: ViewContainerRef) {}

  ngOnInit() {
    console.log('InitComponent');
  }

  ajouteLigne(dateDeb: string | null, dateFin: string | null, energie: number | null) {
    const ref = this.viewContainer.createComponent(LigneSaisieComponent);
    (ref.instance as LigneSaisieComponent).numLigne = this.lignesAjoutees.length + 1;
    if (dateDeb)
      (ref.instance as LigneSaisieComponent).debValue = dateDeb;
    if (dateFin)
      (ref.instance as LigneSaisieComponent).finValue = dateFin;
    if (energie)
      (ref.instance as LigneSaisieComponent).energie = parseInt(energie.toString());
    this.lignesAjoutees.push(ref.instance);
  }

  getLignes(): DataLigneExtrapolation[] {
    let data : DataLigneExtrapolation[] = [];
    for (let i=0; i<this.lignesAjoutees.length; i++) {
      const ref = this.lignesAjoutees[i];
      const d = ref.getValues();
      if (d && d.start != '' && d.end != '' && d.energy)
        data.push(d);
    }
    return data;
  }
}
  
@Component({
  selector: 'app-extrapolateur',
  templateUrl: './extrapolateur.component.html',
  styleUrls: ['./extrapolateur.component.scss'],
  standalone: true,
  imports: [
    CommonModule, IonGrid, IonRow, IonCol, IonLabel, IonList, IonItem, IonInput, IonButton, IonIcon, 
    IonModal, IonAccordion, IonAccordionGroup, LigneSaisieComponent, ListeSaisie 
  ]
})
export class ExtrapolateurComponent implements OnInit {
  @Input() id_courbe!: number;
  @ViewChild('liste_saisie') listeSaisie !: ListeSaisie;

  etatUpload: string = '...';
  couleurMsg: string = 'dark';
  
  constructor(private auth: AuthentificationService, private ps: ProjetService) {}

  ngOnInit() {
    console.log('InitComponent');
  }

  extrapolation(ev : Event){
    const data = this.listeSaisie.getLignes();
    if (data.length == 0) {
      alert("Veuillez compléter au moins une période et l'énergie correspondante.");
      return;
    }
    console.log('extrapolation', data);
    this.appelApiExtrapolation(this.id_courbe, data);
  }

  ajoutePeriode(ev: MouseEvent) {
    this.listeSaisie.ajouteLigne(null, null, null);
  }

  genereLignesSaisie(dataGraphe: any) {
    console.log('ExtrapolateurComponent::genereLignesSaisie : Données du graphe =>', dataGraphe);
    let nbLignes = 0;
    let an : number = 2000;
    let mois : number = 1;
    dataGraphe.forEach( (d: any) => {
      console.log(d);
      if (d.points.manquants > 0) {
        an = parseInt(d.date.substring(0,4));
        mois = parseInt(d.date.substring(5,7));
        const deb = d.date + '-01';
        let fin = d.date + this.finMois(mois);
        this.listeSaisie.ajouteLigne(deb, fin, d.valeurs.total);
        nbLignes += 1;
      }
    });
    /* Si les données ne comportent pas les 12 mois contractuels ...
    while(nbLignes < 12) {
      mois += 1;
      if (mois > 12) {
        mois = 1;
        an += 1;
      }
      const strMois = (mois < 10) ? '0'+mois : ''+mois;
      const deb = '' + an + '-' + strMois + '-01';
      const fin = '' + an + '-' + strMois + this.finMois(mois);
      this.listeSaisie.ajouteLigne(deb, fin, null);
      nbLignes += 1;
    }
    */
  }

  private finMois(mois: number) : string {
    let res = '-28';
    switch(mois) {
      case 1:
      case 3:
      case 5:
      case 7:
      case 8:
      case 10:
      case 12:
        res = '-31';
        break;
      case 4:
      case 6:
      case 9:
      case 11:
        res = '-30';
        break;
    }
    return res;
  }

  async appelApiExtrapolation(idCourbe: number, data : DataLigneExtrapolation[]){
    let token = await this.auth.getToken();
    let url: string = '';
    const payload = { periodes: data};
    let headers : SSEHeaders = {
      'Accept': 'application/json',
      'Authorization': 'Bearer ' + token
    };

    const options : SSEOptions = {
      headers: headers,
      payload: JSON.stringify(payload),
      method: 'POST',
      withCredentials: false,
      start: true,
      debug: true
    };

    url = `${environment.apiHost}api/courbe/${idCourbe}/extrapolation`;
    const sse = new SSE(url, options);
    sse.addEventListener('message', (e: any) => {
      console.log('Event reçu => ', e);
      if (e.data && e.data != '') {
        const res = JSON.parse(e.data);
        console.log('SSE message : ', res);
        if (res.statut == 'progression') {
            this.etatUpload = res.message;
        }
        else if (res.statut == 'erreur') {
          this.etatUpload = res.message + ' (' + res.details + ')';
          this.couleurMsg = 'danger';
        }
        else if (res.statut.toUpperCase() == 'OK') {
         if (res.reponse && res.reponse.extrapolations) {
          this.etatUpload = '' + res.reponse.extrapolations + ' valeurs extrapolées ont été créées.';
          if (res.reponse.extrapolations > 0) {
            this.couleurMsg = 'success';
            this.ps.courbeModifiee.set(this.id_courbe);
          }
          else
            this.couleurMsg = 'warning';
         }
         else
          this.couleurMsg = 'warning';
        }
      }
    });
  }
}
