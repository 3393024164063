/* eslint-disable @angular-eslint/component-selector */
import { Component, Input, OnInit, forwardRef, booleanAttribute } from '@angular/core';
import { CommonModule, NgTemplateOutlet } from '@angular/common';
import { CdkStepper, CdkStepperModule } from '@angular/cdk/stepper';
import { IonList, IonItem, IonHeader, IonFooter, IonBadge } from '@ionic/angular/standalone';

@Component({
  selector: 'tpv-stepper',
  templateUrl: './tpv-stepper.component.html',
  styleUrls: ['./tpv-stepper.component.scss'],
  providers: [{provide: CdkStepper, useExisting: TpvStepperComponent}],
  standalone: true,
  imports: [NgTemplateOutlet, CdkStepperModule, CommonModule, 
    IonList, IonItem, IonHeader, IonFooter, IonBadge
  ],
})

export class TpvStepperComponent extends CdkStepper {
  @Input() mode?: string = 'lateral';
  @Input({ transform: booleanAttribute }) header?: boolean = false;
  @Input({ transform: booleanAttribute }) footer?: boolean = false;

  getBadgeColor(index: number) {
    const step = this.steps.get(index);
    let color = 'primary';
    if (step && step.state) {
      switch(step.state) {
        case 'done':
          color = 'success'; break;
        case 'edit':
            color = 'warning'; break;
        case 'error':
          color = 'danger'; break;
        default:
          color = 'primary';
        
      }
    }
    return color;
  }

  selectStepByIndex(index: number): void {
    //console.log('TpvStepperComponent::selectStepByLabel', this.selected?.label);
    this.selectedIndex = index;
  }
}
