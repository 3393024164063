<div cdkDropList class="example-list" (cdkDropListDropped)="ReordonneProducteurs(sc.id, $event)"[cdkDropListSortingDisabled]="_parent.disableOrdering">
  <div cdkDrag class="full-width bloc-deplacement" *ngFor="let p of producteursTries" [cdkDragDisabled]="_parent.disableOrdering">
    <div *CdkDragPlaceholder></div>
    <ion-item class="full-width"> <!-- Priorité et Nom du participant-->
      <ion-title color="secondary">
        <ion-badge color="primary">{{ prioriteParticipant(p.id) }}</ion-badge> 
        {{ findParticipant(p.id)?.nom }}
      </ion-title>
      <ion-icon name="menu-outline" slot="end" *ngIf="! _parent.disableOrdering" style="cursor: grab;"></ion-icon>
    </ion-item>
    <div class="full-width" *ngFor="let g of p.producteur.generateurs; index as i">
      <mat-tab-group animationDuration="0ms" class="full-width">
        <mat-tab label="Générateur {{g.code}} {{i+1}}">
          <div class="bloc-generateur">
            <div class="titre-generateur">
              <img src="/assets/excel.png" height="18"/> {{ _parent.reductionTexte(_parent.mapDispositifs.get(g.id)?.document, 30) }} 
              <strong>({{ _parent.mapDispositifs.get(g.id)?.puissance }} kWc)</strong>
            </div>
            <div class="bloc-toggle">
              <ion-toggle enable-on-off-labels="true" [checked]="_parent.generateurScenarioActif(sc.id, g.id)" (ionChange)="_parent.activationDispositifScenario($event, sc.id, g.id)">Actif</ion-toggle>
            </div>
          </div>
        </mat-tab>
        <mat-tab label="Ajustement puissances" *ngIf="_parent.generateurScenarioActif(sc.id, g.id)">
          <div>
            <ion-input type="text" name="pcSouhait" class="input-scenario-energie" inputmode="numeric" labelPlacement="floating" color="primary"
              label="(pcSouhait) Puissance produite souhaitée (kW) :" placeholder="La Puissance produite souhaitée (en kW) ..." 
              helper-text="La courbe de puissance du générateur sera ajustée selon le ratio PC / PC Souhaitée."
              error-text="Vous devez saisir la puissance souhaitée du générateur !"
              value="{{ _parent.variableScenario(sc.id, p.id, 'pcSouhait') }}" (ionChange)="_parent.majDispositifScenario($event, sc.id, g.id)">
            </ion-input>
            <ion-input type="text" name="pOndSouhait" class="input-scenario-energie" inputmode="numeric" labelPlacement="floating" color="tertiary"
              label="(pOndSouhait) Puissance des onduleurs (kVA) :" placeholder="La Puissance des onduleurs (en kVA) ..." 
              error-text="La puissance des onduleurs du générateur est invalide !" 
              helper-text="La courbe de puissance des onduleurs sera écrêtée à la valeur éventuellement spécifiée."
              value="{{ _parent.variableScenario(sc.id, p.id, 'pOndSouhait') }}" (ionChange)="_parent.majDispositifScenario($event, sc.id, g.id)">
            </ion-input>
            <ion-input *ngIf=false type="text" name="pcOptMin" class="input-scenario-energie" inputmode="numeric" labelPlacement="floating" color="tertiary"
              label="(pcOptMin) Puissance minimale du générateur (kW) :" placeholder="La Puissance minimale du générateur (en kW) ..." 
              error-text="La puissance minimale du générateur est invalide !" 
              helper-text="La courbe de puissance minimale du générateur sera ajustée à la valeur éventuellement spécifiée."
              value="{{ _parent.variableScenario(sc.id, p.id, 'pcOptMin') }}" (ionChange)="_parent.majDispositifScenario($event, sc.id, g.id)">
            </ion-input>
            <ion-input *ngIf=false type="text" name="pcOptMax" class="input-scenario-energie" inputmode="numeric" labelPlacement="floating" color="tertiary"
              label="(pcOptMax) Puissance maximale du générateur (kW) :" placeholder="La Puissance maximale du générateur (en kW) ..." 
              error-text="La puissance maximale du générateur est invalide !" 
              helper-text="La courbe de puissance maximale du générateur sera ajustée à la valeur éventuellement spécifiée."
              value="{{ _parent.variableScenario(sc.id, p.id, 'pcOptMax') }}" (ionChange)="_parent.majDispositifScenario($event, sc.id, g.id)">
            </ion-input>
            <ion-input *ngIf=false  type="text" name="pcOptDelta" class="input-scenario-energie" inputmode="numeric" labelPlacement="floating" color="tertiary"
              label="(pcOptDelta) Delta du générateur (kW) :" placeholder="Le Delta de puissance du générateur (en kW) ..." 
              error-text="La puissance maximale du générateur est invalide !" 
              helper-text="Le Delta de la courbe de puissance du générateur sera ajustée à la valeur éventuellement spécifiée."
              value="{{ _parent.variableScenario(sc.id, p.id, 'pcOptDelta') }}" (ionChange)="_parent.majDispositifScenario($event, sc.id, g.id)">
            </ion-input>
          </div>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</div>