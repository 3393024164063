<vflow  [nodes]="nodes" [edges]="edges" [view]=[1024,800] (onConnect)="CreeConnexion($event)">
  <ng-template nodeHtml let-ctx>
    <div
      class="custom-node without-tab-index {{ctx.node.data.type}}"
      [class.custom-node_selected]="ctx.selected()"
      tabindex="0"
      (keydown.backspace)="ctx.selected() && deleteNode(ctx.node)"
      (keydown.delete)="ctx.selected() && deleteNode(ctx.node)"
    >
      <div class="type">{{ ctx.node.data.type }}</div>
      <button class="custom-node__button" selectable>{{ ctx.node!.data!.nom }}</button>
      
      @if (ctx.node.data.type === 'compteur') {
      <handle type="source" position="right" [template]="hiddenHandleTemplate"/>
      } @else {
        <handle type="source" position="right" [template]="handleTemplate"/>
      }
      @if (ctx.node.data.type === 'generateur') {
      <handle type="target" position="left" [template]="hiddenHandleTemplate" />
      } @else {
      <handle type="target" position="left" [template]="handleTemplate" />
      }
    </div>
  </ng-template>

  <ng-template #handleTemplate let-ctx>
    <svg:circle
      [attr.cx]="ctx.point().x"
      [attr.cy]="ctx.point().y"
      stroke-width="1"
      stroke="black"
      r="6"
      fill="#1b262c"
    />
  </ng-template>

  <ng-template #hiddenHandleTemplate let-ctx>
    <svg:circle
      [attr.cx]="ctx.point().x"
      [attr.cy]="ctx.point().y"
      stroke-width="0"
      opacity="0"
      stroke="black"
      r="6"
      fill="#1b262c"
    />
  </ng-template>

  <ng-template edge let-ctx>
    <svg:path
      selectable
      class="without-tab-index"
      [attr.d]="ctx.path()"
      [attr.stroke]="ctx.selected() ? '#0f4c75' : '#bbe1fa'"
      fill="none"
      stroke-width="5"
      tabindex="0"
      (keydown.backspace)="ctx.selected() && deleteEdge(ctx.edge)"
    />
  </ng-template>
</vflow>`