<ion-app>
  <ion-header [translucent]="true">
    <ion-toolbar>
      <ion-buttons slot="start">
        <ion-menu-button></ion-menu-button>
      </ion-buttons>
      @if (authInfo) {
        <ion-buttons slot="end">
          <ion-button class="user-infos" size="large" color="secondary" style="height:auto;">
            <ion-chip>
              <ion-avatar>
                  <img alt="{{authInfo.username}}" title="{{authInfo.username}}" src="/assets/avatar.svg" />
              </ion-avatar>
              <ion-label>{{authInfo.prenom}} {{authInfo.nom}}</ion-label>
            </ion-chip>
          </ion-button>
          <ion-button (click)="logout()" alt="Déconnexion ..." title="Déconnexion ...">
            <ion-icon name="walk-sharp" size="large" color="danger"></ion-icon>
          </ion-button>
        </ion-buttons>
      }
      <ion-title>
        {{ titreHeader }}
      </ion-title>
    </ion-toolbar>
  </ion-header>
  @if (authInfo) {
    <ion-split-pane contentId="main-content" style="top:56px" when="{{ showMenu }}">
      <ion-menu contentId="main-content" type="overlay">
        <ion-content>
          <ion-list id="inbox-list">
          @for(p of menuPages; track p; let i = $index) {
            <ion-menu-toggle auto-hide="false" *ngIf="p.restrict ==='' || (p.restrict === 'isAdmin' && isAdmin())">
              <ion-item routerDirection="root" [routerLink]="[p.url]" lines="none" detail="false" routerLinkActive="selected">
                <ion-icon aria-hidden="true" slot="start" [ios]="p.icon + '-outline'" [md]="p.icon + '-sharp'"></ion-icon>
                <ion-label>{{ p.title }}</ion-label>
              </ion-item>
                @if (p.subMenu) {
                  <ion-list id="config-list" style="padding:0 0 0 30px ;">
                    <ion-menu-toggle auto-hide="false" *ngFor="let p2 of p.subMenu; let i = index">
                      <ion-item  routerDirection="root" [routerLink]="[p2.url]" lines="none" detail="false" routerLinkActive="selected">
                        <ion-icon aria-hidden="true" slot="start" [ios]="p2.icon + '-outline'" [md]="p2.icon + '-sharp'"></ion-icon>
                        <ion-label>{{ p2.title }}</ion-label>
                      </ion-item>
                    </ion-menu-toggle>
                  </ion-list>
                }
            </ion-menu-toggle>
          }
          </ion-list>
        </ion-content>
      </ion-menu>
      <ion-router-outlet id="main-content"></ion-router-outlet>
    </ion-split-pane>
  } @else {
    <ion-router-outlet id="connexion-content"></ion-router-outlet>
  }

</ion-app>
