import { Component, effect, Input, OnInit } from '@angular/core';
import { ECharts, EChartsOption, dataTool } from 'echarts';
import { ProjetService } from 'src/app/services/projet.service';
import { IonContent, IonTitle, IonText, IonGrid, IonRow, IonCol, IonRouterLink, IonLabel, IonList, 
  IonItem, IonButtons, IonButton, IonInput, IonSelect, IonSelectOption, IonIcon,
  IonCard, IonCardHeader, IonCardTitle, IonCardSubtitle, IonCardContent,
  IonHeader, IonToolbar, IonSegment, IonSegmentButton, ModalController, IonModal } from '@ionic/angular/standalone';
import { NgxEchartsDirective, provideEcharts } from 'ngx-echarts';
import { CommonModule } from '@angular/common';
import { ReponseApi } from 'src/app/services/EntiteProjet';

interface TPVData {
  date: string;
  valeur: number;
  points: number;
}

interface ValueMapItem {
  week: number;
  hour: number;
  value: number;
}
@Component({
  selector: 'app-dialogue-heatmap',
  templateUrl: './dialogue-heatmap.component.html',
  styleUrls: ['./dialogue-heatmap.component.scss'],
  standalone: true,
  imports: [
    CommonModule, IonContent, IonTitle, IonText, IonGrid, IonRow, IonCol, IonRouterLink, IonButtons,
    IonLabel, IonList, IonItem, IonInput, IonSelect, IonSelectOption, IonButton, IonIcon, 
    IonHeader, IonToolbar, IonSegment, IonSegmentButton, NgxEchartsDirective, IonCard, IonCardHeader, IonCardTitle, IonCardSubtitle, IonCardContent, IonModal 
  ],
  providers: [ provideEcharts() ]
})
export class DialogueHeatmapComponent  implements OnInit {
  @Input() id_courbe!: number;

  retourApi : ReponseApi | any;
  titreModal? :string;
  echartsOptions: EChartsOption = {};
  ecInstance: ECharts | null = null;
  
  xData: number[] = [];
  yData: number[] = [];
  maxValue: number = 0;
  minValue: number = -1;

  TPVdata: [TPVData[]] = [[]]; // Assurez-vous d'initialiser TPVdata avec vos données
  result: [number, number, number][] = [];
  data: number[] = [];
  
  constructor(private modalCtrl: ModalController, private ps: ProjetService) {
    console.log("chargement de la heatmap");
   }
  ngOnInit() {
    this.titreModal = "Surplus ACC Global (kWh)";
    this.recupereData().then(() => {
      console.log('DialogueGrapheComponent => Données rechargées.');
    });
  }
  onChartInit(ec: any) {
    console.log('onChartInit()', ec);
    this.ecInstance = ec;
  }
  onChartClick(ev: any) {
    console.log('onChartClick', ev);
  }

  async recupereData(){
    this.retourApi = await this.ps.donneesCourbeHeatmap(this.id_courbe);
    this.TPVdata.unshift(<TPVData[]>this.retourApi);


    this.afficheGraphe();
    
  }

  afficheGraphe(ev?: CustomEvent){

    // console.log("afficheGraphe()", ev?.detail.value)
    this.result = this.convertTPVData(ev?.detail.value);

    let formatteNombre: any = this.ps.formatteNombre;
    this.echartsOptions = {
      tooltip: {
        formatter:  function (params:any) {
          return `${params.seriesName}<br/>
                  Semaine ${params.value[1]+1} - ${params.value[0]+6}h - ${formatteNombre(params.value[2])} kWh`;
        },},
      xAxis: {
          type: 'category',
          data: this.xData,
          position: 'top',
          name: 'Heure'
      },
      yAxis: {
          type: 'category',
          data: this.yData,
          inverse: true,
          name: 'Semaine',
          // startValue: 1
      },
      visualMap: {
          min: this.minValue,
          max: this.maxValue,
          calculable: true,
          realtime: true,
          inRange: {
              color: [
                  '#313695',
                  '#4575b4',
                  '#74add1',
                  '#abd9e9',
                  '#e0f3f8',
                  '#ffffbf',
                  '#fee090',
                  '#fdae61',
                  '#f46d43',
                  '#d73027',
                  '#a50026'
              ]
          }
      },
      series: [
          {
              name: 'Surplus',
              type: 'heatmap',
              data: this.result,
              label: {
                  show: true
              },
              emphasis: {
                  itemStyle: {
                      borderColor: '#333',
                      borderWidth: 1
                  }
              },
              progressive: 1000,
              animation: false
          }
      ]
    };
  }

  async fermeModal() {
    await this.modalCtrl.dismiss(null, 'annule');
  }  
   
  getWeekNumber(d: Date): [number, number] {
      d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
      d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
      let yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
      let weekNo = Math.ceil(((d.getTime() - yearStart.getTime()) / 86400000 + 1) / 7);
      return [d.getUTCFullYear(), weekNo];
  }
   
  convertTPVData(range : string = 'tout'): [number, number, number][] {
      let result: [number, number, number][] = [];
      let valueMap: { [key: string]: ValueMapItem } = {};
      let heureDeb :number = 6;
      let heureFin :number = 21;
      let semaineDeb :number = 52;
      this.maxValue = 0;
      this.minValue = -1;

      this.TPVdata[0].forEach(data => {
        let date = new Date(data.date);
        
        let [year, week] = this.getWeekNumber(date);
        let hour = date.getHours();
        let value = Math.round(data.valeur);

        if (hour >= heureDeb && hour <= heureFin) {
          // let key = `${year}-${week}-${hour}`;
          let key = `${week}-${hour}`;
          if (!valueMap[key]) {
            valueMap[key] = { week, hour, value: 0 };
          }

          if (range == 'tout')
            valueMap[key].value += value;    
          else if(range == "semaine")
            valueMap[key].value += (date.getDay() > 0 && date.getDay() < 6) ? value : 0 ;
          else if(range == "samedi")
            valueMap[key].value += (date.getDay() == 6) ? value : 0 ;
          else if(range == "dimanche")
            valueMap[key].value += (date.getDay() == 0) ? value : 0 ;
          else if(range == "weekend")
            valueMap[key].value += (date.getDay() == 0 || date.getDay() == 6) ? value : 0 ;
          
          this.maxValue = (this.maxValue < valueMap[key].value ) ? valueMap[key].value : this.maxValue;
          this.minValue = (this.minValue > valueMap[key].value || this.minValue == -1) ? valueMap[key].value : this.minValue;
          semaineDeb = (semaineDeb < week ) ? semaineDeb : week;

          if(!this.xData.includes(hour))
            this.xData.push(hour);
          if(!this.yData.includes(week))
            this.yData.push(week);
        }
      });
      this.yData.sort((n1,n2) => n1 - n2);

      Object.values(valueMap).forEach(item => {
        // console.log("item.hour-heureDeb, item.week-semaineDeb, item.value",item.hour-heureDeb, item.week-semaineDeb, item.value);
        result.push([item.hour-heureDeb, item.week-semaineDeb, item.value]);
      });
      
      return result;
  }  
}
