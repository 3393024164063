import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormsModule, ReactiveFormsModule} from '@angular/forms';
import { IonContent, IonTitle, IonText, IonGrid, IonRow, IonCol, IonRouterLink, IonLabel, IonList, 
  IonItem, IonButtons, IonButton, IonInput, IonSelect, IonSelectOption, IonIcon,
  IonCard, IonCardHeader, IonCardTitle, IonCardSubtitle, IonCardContent,
  IonHeader, IonToolbar, IonSegment, IonSegmentButton, ModalController } from '@ionic/angular/standalone';
  import { TypeProjet } from 'src/app/services/EntiteProjet';

@Component({
  selector: 'app-dialogue-creation-projet',
  templateUrl: './dialogue-creation-projet.component.html',
  styleUrls: ['./dialogue-creation-projet.component.scss'],
  standalone: true,
  imports: [
    CommonModule, IonContent, IonTitle, IonText, IonGrid, IonRow, IonCol, IonRouterLink, IonButtons,
    IonLabel, IonList, IonItem, IonInput, IonSelect, IonSelectOption, IonButton, IonIcon, 
    IonHeader, IonToolbar, IonSegment, IonSegmentButton, 
    IonCard, IonCardHeader, IonCardTitle, IonCardSubtitle, IonCardContent, ]
})
export class DialogueCreationProjetComponent  implements OnInit {
  listeTypesProjet: string[] = [];

  constructor(private modalCtrl: ModalController) { 
    this.listeTypesProjet = Object.keys(TypeProjet);
  }

  ngOnInit() {
    console.log('InitComponent');
  }

  getLibelleType(index: number) {
    let libs = Object.values(TypeProjet);
    return libs[index];
   }

   async confirmeCreationProjet(libelle: any, type: any, nbConsommateurs: any) {
    console.log('confirmeCreationProjet', libelle, type, nbConsommateurs);
    await this.modalCtrl.dismiss({"libelle": libelle, "type": type, "nbConsommateurs": nbConsommateurs}, 'confirme');
   }

   async annuleCreationProjet(event: MouseEvent) {
    await this.modalCtrl.dismiss(null, 'annule');
   }

}
