import { Component, OnInit, Signal, effect } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavigationEnd, Router, RouterLink, RouterLinkActive } from '@angular/router';
import { IonApp, IonSplitPane, IonRouterOutlet, IonContent, IonMenu, IonMenuButton, IonMenuToggle, IonList, IonListHeader, IonItem, IonNote, IonHeader, IonToolbar, IonNav, IonButtons, IonButton, IonChip, IonAvatar, IonLabel, IonIcon, IonTitle } from '@ionic/angular/standalone';
import { addIcons } from 'ionicons';
import { walkSharp, homeOutline, homeSharp, businessOutline, businessSharp, optionsSharp, chevronForwardOutline, chevronForwardSharp, book } from 'ionicons/icons';
import { AuthTokenInfo, AuthentificationService } from './services/authentification.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [CommonModule, RouterLink, RouterLinkActive, 
    IonApp, IonSplitPane, IonMenu, IonMenuButton, IonMenuToggle, IonList, IonListHeader, IonItem, IonNote, IonRouterOutlet, IonContent, IonHeader, IonToolbar, 
    IonNav, IonButtons, IonButton, IonChip, IonAvatar, IonLabel, IonIcon, IonTitle
  ]
})
export class AppComponent implements OnInit {
  readonly tokenInfoSignal!: Signal<AuthTokenInfo | null>;
  readonly titreHeaderSignal!: Signal<string>;
  readonly showMenuSignal!: Signal<string>;
  authInfo!: AuthTokenInfo | null;
  showMenu: string = "lg";
  titreHeader: string = '';


  menuPages = [
    {title: 'Accueil', url: '/home', icon: 'home', restrict: '' },
    {title: 'Projets', url: '/projet', icon: 'business', restrict: '' },
    {title: 'Configuration', url: '/config', icon: 'options', restrict: 'isAdmin', subMenu :[
      {title: 'TURPE', url: '/config/turpe', icon: 'chevron-forward'},
      {title: 'Taxes', url: '/config/taxes', icon: 'chevron-forward'},
      {title: 'Plan Financier', url: '/config/plan-financier', icon: 'chevron-forward'}
    ]}
  ];

  constructor(private auth: AuthentificationService, private router: Router) {
    this.tokenInfoSignal = auth.tokenInfoSignal;
    this.titreHeaderSignal = auth.headerTitreSignal;
    this.showMenuSignal = auth.menuLateralSignal;

    effect(() => {
      this.authInfo = this.tokenInfoSignal();
      this.titreHeader = this.titreHeaderSignal();
      this.showMenu = this.showMenuSignal();
    });

    addIcons({ walkSharp, homeOutline, homeSharp, businessOutline, businessSharp, optionsSharp, chevronForwardOutline, chevronForwardSharp});

    const routerSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        routerSubscription.unsubscribe();
//        console.log('event nav : ', event);
        if (event.url.match('/projet/')) {
          this.auth.menuLateralSignal.set('false');
        } else {
          this.auth.menuLateralSignal.set('lg');
        }
//        console.log('this.showMenu = ' + this.showMenuSignal());
      }
        
      });
  }

  ngOnInit(): void {
    console.log('AppComponent::ngOnInit');
  }

  logout(){
    this.auth.logOut();
  }

  isAdmin(): boolean {
    let res: boolean = false;
    this.authInfo?.roles.forEach( r => {
      if (r == 'ROLE_ADMIN')
        res = true;
    });
    return res;
  }
}
