<ion-content>
  <ion-header>
    <ion-toolbar>
      <ion-buttons slot="start">
        <ion-button (click)="annuleImportProjet($event)">Annuler</ion-button>
      </ion-buttons>
      <ion-title>Importer un nouveau projet</ion-title>
      <ion-buttons slot="end">
        <ion-button (click)="annuleImportProjet($event)" [strong]="true">Fermer</ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>
  <ion-content class="ion-padding" style="position: fixed;">
    <ion-card>
      <ion-card-header>Télécharger un nouveau fichier<div class="right">(formats de fichiers acceptés : .xlsb)</div></ion-card-header>
      <ion-card-content>
        <app-upload-file type_fichier="Projet" formats_acceptes=".xlsb" [id_projet]="idProjet"></app-upload-file>
      </ion-card-content>
    </ion-card>
  </ion-content>
</ion-content>