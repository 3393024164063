@if (mode === 'lateral') {
<div class="tpv-stepper">
  <div class="step-menu">
    <ion-list>
      @for (step of steps; track step; let i = $index) {
        <ion-item>
          <button class="example-step" [class.example-active]="selectedIndex === i" (click)="selectStepByIndex(i)">
            <ion-badge [color]="getBadgeColor(i)">{{ i + 1 }}</ion-badge> {{ step.label }}
          </button>
        </ion-item>           
      }
    </ion-list>
  </div>
  <div class="step-pane">
    <ion-header *ngIf="header">
      <h2>Step {{ selectedIndex + 1 }}/{{ steps.length }}</h2>
    </ion-header> 
    <div id="step-content" [ngTemplateOutlet]="selected ? selected.content : null"></div>
    <ion-footer class="example-step-navigation-bar" *ngIf="footer">
      <button class="example-nav-button" cdkStepperPrevious>&larr;</button>
      @for (step of steps; track step; let i = $index) {
        <button
          class="example-step"
          [class.example-active]="selectedIndex === i"
          (click)="selectStepByIndex(i)">Step {{ i + 1 }}</button>
      }
      <button class="example-nav-button" cdkStepperNext>&rarr;</button>
    </ion-footer>
  </div>
</div>
} @else if (mode === 'menubar') {
<div class="tpv-stepper-menutab">
  <div class="step-menu-menutab">
    <ion-list>
      @for (step of steps; track step; let i = $index) {
          <button class="example-step" [class.example-active]="selectedIndex === i" (click)="selectStepByIndex(i)">
            <!-- <ion-badge [color]="getBadgeColor(i)">{{ i + 1 }}</ion-badge> -->
             {{ step.label }}
          </button>
      }
    </ion-list>
  </div>
  <div class="step-pane-menutab">
    <ion-header *ngIf="header">
      <h2>Step {{ selectedIndex + 1 }}/{{ steps.length }}</h2>
    </ion-header> 
    <div id="step-content" [ngTemplateOutlet]="selected ? selected.content : null"></div>
    <ion-footer class="example-step-navigation-bar" *ngIf="footer">
      <button class="example-nav-button" cdkStepperPrevious>&larr;</button>
      @for (step of steps; track step; let i = $index) {
        <button
          class="example-step"
          [class.example-active]="selectedIndex === i"
          (click)="selectStepByIndex(i)">Step {{ i + 1 }}</button>
      }
      <button class="example-nav-button" cdkStepperNext>&rarr;</button>
    </ion-footer>
  </div>
</div>
}