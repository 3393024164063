import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  private api_host: string = (environment.apiHost) ? environment.apiHost: 'https://api.tpvdev.tecsol.fr/';
  private api_listeProjets: string = 'api/projet/';
  private api_infosProjet: string = 'api/projet/@1/';
  private api_upload: string = 'api/projet/@1/upload/@2/';
  private calcul_bilan: string = 'api/projet/@1/calcul/bilan/';

  constructor() { }

  public getApiURL(action: ApiActions, idProjet?: number, uploadType?: UploadTypes) {
    let url: string = this.api_host;
    let id_projet: string = (idProjet) ? '' + idProjet : '-1';
    let type_upload : string = (uploadType) ? uploadType : 'generique';
    switch(action) {
      case ApiActions.listeProjets: url += this.api_listeProjets; break;
      case ApiActions.infosProjet:  url += this.api_infosProjet.replace('@1', id_projet); break;
      case ApiActions.uploadFile:   url += this.api_upload.replace('@1', id_projet).replace('@2', type_upload); break;
      case ApiActions.calculBilan:  url += this.calcul_bilan.replace('@1', id_projet); break;
      default: url += 'action_inconnue';
    }
    return url;
  }

  public CatTurpe6() {
    return [
      'HTB',
      'HTA',
      'BT>36kVA',
      'BT<36kVA',
    ];
  }

  public sousCatTurpe6(cat:string | undefined) {
    let res: string[] = [];
    if (cat == undefined)
      return [ 'Sélectionnez la catégorie'];
    switch(cat) {
      case 'HTB':
        res = [
          'HTB2 MU',
        ];
        break;
      case 'HTA':
        res = [
          'HTA CU pointe fixe',
          'HTA CU pointe mobile',
          'HTA LU pointe fixe',
          'HTA LU pointe mobile',
          'HTA LU pointe mobile + Injection',
        ];
        break;
      case 'BT>36kVA':
        res = [
          'BT>36 CU',
          'BT>36 LU',
        ];
        break;
      case 'BT<36kVA':
        res = [
          'BT<36 CU 4',
          'BT<36 MU 4',
          'BT<36 CU',
          'BT<36 MUTD',
          'BT<36 LU',
        ];
        break;
      default:
        res = [ 'catégorie invalide' ];
    }
    return res;
  }
}

export enum ApiActions {
  listeProjets = "liste_projets",
  infosProjet = "infos_projet",
  uploadFile = "upload_file",
  calculBilan = "calcul/bilan",
  configTurpe = "configuration/turpe",
}


export enum UploadTypes {
  Conso = 'conso',
  Prod = 'prod'
}
