<ion-header [translucent]="true">
  <ion-toolbar>
    <ion-title>Connexion</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content [fullscreen]="true">
  <ion-header collapse="condense">
    <ion-toolbar>
      <ion-title size="large">Connexion</ion-title>
    </ion-toolbar>
  </ion-header>

  <ion-card>
    <img alt="Logo Tecsol" src="https://custom-images.strikinglycdn.com/res/hrscywv4p/image/upload/c_limit,fl_lossy,h_300,w_300,f_auto,q_auto/216977/815441_261713.png" />
    <ion-card-header>
      <ion-card-title>Connexion à Tecsol PV - version {{ VERSION }}</ion-card-title>
      <!-- <ion-card-subtitle>{{auth.authInfo().token}}</ion-card-subtitle> -->
    </ion-card-header>
  
    <ion-card-content>
      <form (keyup.enter)="onKeyEnter($event)">
        <ion-item lines="full">
          <ion-input type="text" value="" label="Email" required #login></ion-input>
        </ion-item>
        <ion-item lines="full">
          <ion-input id="passwd" type="password" value="" label="Mot de passe" required #passwd></ion-input>
        </ion-item>
        <ion-row>
          <ion-col>
            <ion-button (click)="connexion()" type="submit" color="primary" expand="block">Connexion</ion-button>
            <!-- <a  class="small-text">Forgot Password?</a> -->
          </ion-col>
        </ion-row>
      </form>
    </ion-card-content>
  </ion-card>
</ion-content>
