<ion-item *ngIf="! supprime">
  <ion-grid>
    <ion-row>
      <ion-col size="4"><ion-datetime-button datetime="datetime{{numLigne}}_a" [color]="dateDebOk"></ion-datetime-button></ion-col>
      <ion-col size="4"><ion-datetime-button datetime="datetime{{numLigne}}_b" [color]="dateFinOk"></ion-datetime-button></ion-col>
      <ion-col size="3"><!--
        <ion-input #energy type="text" name="energy" fill="outline" label="" placeholder="" size="small"
            inputmode="numeric" error-text="Vous devez saisir la consommation du mois !" value="">
        </ion-input>-->
        <input #energy type="text" name="energy" [value]="energie"/>
      </ion-col>
      <ion-col size="1" class="ion-text-right"><ion-icon name="trash" color="danger" (click)="supprimePeriode($event)"></ion-icon></ion-col>
    </ion-row>
  </ion-grid>
</ion-item>

<ion-modal [keepContentsMounted]="true">
  <ng-template>
    <ion-datetime #deb id="datetime{{numLigne}}_a" presentation="date" (ionChange)="dateSel($event); deb.cancel(true)" [value]="debValue"></ion-datetime>
  </ng-template>
</ion-modal>
<ion-modal [keepContentsMounted]="true">
  <ng-template>
    <ion-datetime #fin id="datetime{{numLigne}}_b" presentation="date" (ionChange)="dateSel($event); fin.cancel(true)" [value]="finValue"></ion-datetime>
  </ng-template>
</ion-modal>