<div class="echart-legend-container">
    <div id="chart-flux-container" echarts [options]="echartFluxOption" (chartInit)="onChartInit($event)" (chartClick)="onChartClick($event)"></div>
    <ul id="legend">
        <li id="node-producteur" class="legend-item">
            <span class="legend-color"></span>
            <span class="legend-text">Producteurs</span>
        </li>
        <li id="flux-from-soutirage" class="legend-item">
            <span class="legend-color"></span>
            <span class="legend-text">Conso reseau</span>
        </li>
        <li id="flux-aci" class="legend-item">
            <span class="legend-color"></span>
            <span class="legend-text">ACI</span>
        </li>
        <li id="flux-from-prod" class="legend-item">
            <span class="legend-color"></span>
            <span class="legend-text">ACC</span>
        </li>
        <li id="flux-to-surplus" class="legend-item">
            <span class="legend-color"></span>
            <span class="legend-text">Surplus</span>
        </li>
        <li id="node-consommateur" class="legend-item">
            <span class="legend-color"></span>
            <span class="legend-text">Consommateurs</span>
        </li>
    </ul>
</div>