<ion-item>
  <ion-label>Option tarifaire : {{libelleTarif}}</ion-label>
  <ion-icon id="open-modal-{{id_compteur}}" name="pencil" [color]="couleurIconeSaisie"></ion-icon>
</ion-item>

<ion-modal trigger="open-modal-{{id_compteur}}" (willDismiss)="onWillDismiss($event)">
  <ng-template>
    <ion-header>
      <ion-toolbar>
        <ion-title>Choix des Options Tarifaires</ion-title>
        <ion-buttons slot="end">
          <ion-button (click)="valideOption()" [strong]="true">Sauvegarder</ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content class="ion-padding">
      <ion-item>
        <ion-select #optionTarif id="optionTarif" placeholder="Choisir une option" (ionChange)="choixOptionTarifaire($event);" value="{{libelleTarif}}" interface="popover">
          <ion-select-option value="BASE">BASE</ion-select-option>
          <ion-select-option value="HP/HC">HP/HC</ion-select-option>
          <ion-select-option value="P/HCH/HPH/HCB/HPB">P/HCH/HPH/HCB/HPB</ion-select-option>
        </ion-select>
      </ion-item>
      <ion-item *ngIf="choixTarif > 0">
        <ion-label>Plages Heures Creuses :</ion-label>
      </ion-item>
      <ion-item *ngIf="choixTarif > 0">
        <mat-form-field>
          <mat-label>Début</mat-label>
          <input #HC1deb matInput type="time" [value]="HC1debVal">
        </mat-form-field>
        <mat-form-field>
          <mat-label>Fin</mat-label>
          <input #HC1fin matInput type="time" [value]="HC1finVal">
        </mat-form-field>
        <ion-button (click)="togglePeriode()" class="bfl" [disabled]="secondeHC">Ajout période</ion-button>
      </ion-item>
      <ion-item *ngIf="choixTarif > 0 && secondeHC">
        <mat-form-field>
          <mat-label>Début</mat-label>
          <input #HC2deb matInput type="time" [value]="HC2debVal">
        </mat-form-field>
        <mat-form-field>
          <mat-label>Fin</mat-label>
          <input #HC2fin matInput type="time" [value]="HC2finVal">
        </mat-form-field>
        <ion-button (click)="togglePeriode()" class="bfl">Supprime période</ion-button>
      </ion-item>

      <ion-item *ngIf="choixTarif > 1">
        <ion-label>La saison tarifaire « Hiver » s'étend du 1er novembre au 31 mars inclus ; la saison tarifaire « Eté » s'étend du 1er avril inclus au 31 octobre inclus. (Enedis)</ion-label>
      </ion-item>

      <ion-item *ngIf="choixTarif > 1">
        <mat-checkbox color="primary" (click)="togglePointe()" [checked]="pointeBool">Période de Pointe</mat-checkbox>

        <mat-radio-group *ngIf="pointeBool" (change)="choixPointe($event);">
          <mat-radio-button color="primary" value="pointeF">Pointe Fixe</mat-radio-button>
          <mat-radio-button color="primary" value="pointeM" disabled>Pointe Mobile</mat-radio-button>
        </mat-radio-group>
      </ion-item>
      <ion-item *ngIf="choixPointeF">
        <ion-label>2h le matin et 2h le soir, de décembre à février inclus, hors dimanche</ion-label>
      </ion-item>

      <ion-item *ngIf="choixPointeF">
        <ion-label>Plages Pointes :</ion-label>
      </ion-item>
      <ion-item *ngIf="choixPointeF">
        <mat-form-field>
          <mat-label>Début</mat-label>
          <input #P1deb matInput type="time" [value]="P1debVal">
        </mat-form-field>
        <mat-form-field>
          <mat-label>Fin</mat-label>
          <input #P1fin matInput type="time" [value]="P1finVal">
        </mat-form-field>
        <ion-button (click)="togglePeriodePointe()" class="bfl" [disabled]="secondePointe">Ajout période</ion-button>
      </ion-item>
      <ion-item *ngIf="choixPointeF && secondePointe">
        <mat-form-field>
          <mat-label>Début</mat-label>
          <input #P2deb matInput type="time" [value]="P2debVal">
        </mat-form-field>
        <mat-form-field>
          <mat-label>Fin</mat-label>
          <input #P2fin matInput type="time" [value]="P2finVal">
        </mat-form-field>
        <ion-button (click)="togglePeriodePointe()" class="bfl">Supprime période</ion-button>
      </ion-item>
      <!-- SAISIE DES TARIFS DISTRIBUTEUR -->
      <ion-list class="saisie-tarif">
        @if (libelleTarif === 'BASE') {
        <ion-item>
          <ion-label>Tarif du kWh (c€) :</ion-label>
          <input #tarif_BASE matInput [value]="tarifBASE">
        </ion-item>
        }
        @if (libelleTarif === 'HP/HC') {
        <ion-item>
          <ion-label>Tarif du kWh en Heures Creuses (c€) :</ion-label>
          <input #tarif_HC matInput [value]="tarifHC">
        </ion-item>
        <ion-item>
          <ion-label>Tarif du kWh en Heures Pleines (c€) :</ion-label>
          <input #tarif_HP matInput [value]="tarifHP">
        </ion-item>
        }
        @if (libelleTarif === 'P/HCH/HPH/HCB/HPB') {
          @if (choixPointeF) {
          <ion-item>
            <ion-label>Tarif du kWh en Heures de Pointe (c€) :</ion-label>
            <input #tarif_P matInput [value]="tarifP">
          </ion-item>
        }
        <ion-item>
          <ion-label>Tarif du kWh en Heures Creuses Haute Saison (c€) :</ion-label>
          <input #tarif_HCH matInput [value]="tarifHCH">
        </ion-item>
        <ion-item>
          <ion-label>Tarif du kWh en Heures Pleines Haute Saison (c€) :</ion-label>
          <input #tarif_HPH matInput [value]="tarifHPH">
        </ion-item>
        <ion-item>
          <ion-label>Tarif du kWh en Heures Creuses Basse Saison (c€) :</ion-label>
          <input #tarif_HCB matInput [value]="tarifHCB">
        </ion-item>
        <ion-item>
          <ion-label>Tarif du kWh en Heures Pleines Basse Saison (c€) :</ion-label>
          <input #tarif_HPB matInput [value]="tarifHPB">
        </ion-item>
        }
      </ion-list>
    </ion-content>
  </ng-template>
</ion-modal>